import {call, put} from "redux-saga/effects";
import {fetchDeposit, fetchDepositCheckBankPw} from "../../../lib/api";
import {
    failedGetDepositCheckBankPwReducer,
    failedGetDepositReducer,
    setDepositCheckBankPwReducer,
    setDepositReducer
} from "../../reducers/menu3/depositReducer";

export function* getDepositSaga(action) {
    console.log("##getDepositSaga##");
    try{
        const res = yield call(fetchDeposit,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.userNo);
        yield put(setDepositReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDepositReducer(error))
    }
}

export function* getDepositCheckBankPwSaga(action) {
    console.log("##getDepositCheckBankPwSaga##",action.payload.no,action.payload.name,action.payload.bankPw);
    try{
        const res = yield call(fetchDepositCheckBankPw,action.payload.no,action.payload.name,action.payload.bankPw);
        yield put(setDepositCheckBankPwReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDepositCheckBankPwReducer(error))
    }
}
