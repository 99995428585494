import React from 'react';
import moment from "moment";
import {NumericFormat} from "react-number-format";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setBettingListPage} from "../../redux/reducers/menu1/bettingListReducer";
const BettingListComponent = ({initialState,reloadBetList}) => {
    const dispatch = useDispatch()
    function getDetailInfo(detail) {
        let selectTeam
        let selectBet
        if(detail.bet_no === 1) {
            selectTeam = "홈 " + detail.low_ratio.home
            selectBet = detail.bet1
        }
        else if(detail.bet_no === 2) {
            selectTeam = "무"
            selectBet = detail.bet2
        }
        else if(detail.bet_no === 3) {
            selectTeam = "원정 " + detail.low_ratio.away
            selectBet = detail.bet3
        }
        return (
            <>
                <td>{selectTeam}</td>
                <td>{detail.score_home} : {detail.score_away}</td>
                <td>{selectBet}배</td>
                <td>{detail.result === 0 ? "대기" : detail.result === 2? "당첨" : (detail.result === 3? "적특" : "낙첨")}</td>
            </>
        )
    }

    const userBetDelete = (no) =>{
        console.log("##no##",no);
        const isConfirmed = window.confirm('삭제하시겠습니까?');
        if (isConfirmed) {
            axios.post('/user//bet/updateDeleteBet', {
                no: no,
            }).then((res) => {
                let page = 0;
                alert(res.data.isInserted? "베팅내역 삭제완료" : "베팅내역 삭제실패")
                if(initialState.userBetCount === 1){
                    page = initialState.page - 1;
                }else{
                    page = initialState.page
                }
                reloadBetList(page)
            })
        }
    }

    return (
        <div>
            <h4 style={{ color: 'white' }}>스포츠 베팅 내역</h4> {/* 타이틀 추가 */}
                {initialState.userBets.map((userBet) => (
                    <table key={userBet.no} className="table table-sm text-center align-middle bettingList_content">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "14%"}}>경기시간</th>
                            <th style={{width: "12%"}}>리그명</th>
                            <th style={{width: "24%"}}>홈팀 vs 원정팀</th>
                            <th style={{width: "10%"}}>타입</th>
                            <th style={{width: "10%"}}>배팅팀</th>
                            <th style={{width: "10%"}}>스코어</th>
                            <th style={{width: "10%"}}>배당</th>
                            <th style={{width: "10%"}}>상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userBet.user_bet_details.map((detail) => (
                            <tr key={detail.no}>
                                <td>{moment(detail.low_ratio.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                <td>{detail.low_ratio.league}</td>
                                <td>
                                    홈팀 : {detail.low_ratio.home} <br></br>
                                    원팀 : {detail.low_ratio.away}
                                </td>
                                <td>{detail.low_ratio.market}</td>
                                {getDetailInfo(detail)}
                            </tr>
                        ))}
                        <tr style={{background: "black"}}>
                            <td colSpan="6">
                                <div style={{display: "flex" , fontSize: "12px"}}>
                                    <div style={{marginLeft: "10px"}}>
                                        배팅접수시간 : <span style={{color: "gold"}}>{moment(userBet.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
                                    </div>
                                    <div style={{marginLeft: "10px"}}>
                                        배팅금액 : <span style={{color: "gold"}}><NumericFormat value={userBet.money} displayType={'text'} thousandSeparator={true} /></span>
                                    </div>
                                    <div style={{marginLeft: "10px"}}>
                                        총배당 : <span style={{color: "gold"}}>{userBet.total_ratio}</span>
                                    </div>
                                    <div style={{marginLeft: "10px"}}>
                                        예상당첨금액 : <span style={{color: "gold"}}><NumericFormat value={userBet.win_money} displayType={'text'} thousandSeparator={true} /></span>
                                    </div>
                                    <div style={{marginLeft: "10px"}}>
                                        결과당첨금액 : <span style={{color: "gold"}}><NumericFormat value={userBet.result === 2? userBet.result_money : 0} displayType={'text'} thousandSeparator={true} /></span>
                                    </div>
                                </div>
                            </td>
                            <td colSpan="2" style={{fontSize: "12px"}}>
                                <div className="text-end">
                                    <button className="bettingListButton1">{userBet.result === 0 ? "대기" : userBet.result !== 1? "당첨" : "낙첨"}</button>
                                    <button className="bettingListButton2" onClick={() => userBetDelete(userBet.no)}>배팅내역삭제</button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                ))}
            </div>
    )
}
export default BettingListComponent;