import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const TokenGame = () => {
    return (
        <div className="main">
            <div className="subtitle">
                <div className="mt-1  p-3 border border-info">
                토큰게임 TOKEN GAME
                </div>
            </div>
        </div>
    )
}
export default TokenGame;