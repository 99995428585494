import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const attendSlice = createSlice({
    name: 'attend',
    initialState: {
        isLoading: false,
        error: null,
        attend: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        userNo: "",
    },
    reducers: {
        getAttend(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.userNo = action.payload.userNo;
        },
        setAttend(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.attend = action.payload.result;
            state.attendChkDate = action.payload.attendChkDate;
        },
        failedGetAttend(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getAttend, setAttend, failedGetAttend } = attendSlice.actions;

export default attendSlice.reducer;