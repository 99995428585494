import React, {useEffect} from 'react';
import moment from "moment/moment";

const GameResultComponent = ({initialState}) => {
    return (
        <div>
                <table className="table table-sm text-center align-middle gameResult_content">
                    <thead className="table-dark">
                    <tr>
                        <th style={{width: "14%"}}>경기시간</th>
                        <th style={{width: "12%"}}>리그명</th>
                        <th style={{width: "24%"}}>홈팀 vs 원정팀</th>
                        <th style={{width: "10%"}}>배팅팀</th>
                        <th style={{width: "10%"}}>스코어</th>
                        <th style={{width: "10%"}}>상테</th>
                    </tr>
                    </thead>
                    {initialState.gameResultList&&initialState.gameResultList.map((gameResult, index) => (
                    <tbody key={gameResult.id}>
                    <tr>
                        <td>{moment(gameResult.start_date).format("YYYY-MM-DD HH:mm")}</td>
                        <td> {gameResult.league}</td>
                        <td>
                            홈팀 : {gameResult.home} <br></br>
                            원팀 : {gameResult.away}
                        </td>
                        <td>{gameResult.market}</td>
                        <td>{gameResult.home_score} : {gameResult.away_score}</td>
                        <td>{gameResult.status}</td>
                    </tr>

                    </tbody>
                    ))}
                </table>
        </div>
    )
}
export default GameResultComponent;