import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {NumericFormat} from "react-number-format";
import {getBettingListReducer} from "../../redux/reducers/menu1/bettingListReducer";
import BettingListComponent from "../../components/menu1/bettinglistComponent";
import Pagination from "../../components/common/Pagination";
const BettingListContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.bettingListReducer)
    const userInfo = useSelector((state) => state.userInfo);
    const getBetList = () => {
        if(initialState.userBets !== null) {
            dispatch(getBettingListReducer({
                page:initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                userNo: userInfo.userNo,
            }))
        }
    }

    const handlePageChange = (page) => {
        dispatch(getBettingListReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            userNo: userInfo.userNo,
        }))
    }

    useEffect(getBetList, [dispatch])


    const reloadBetList = (page) => {
        if(initialState.userBets !== null) {
            dispatch(getBettingListReducer({
                page:page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                userNo: userInfo.userNo,
            }))
        }
    }
    return (
        <div className="main">
            <div className="subtitle">
                배팅내역 BETTING LIST
            </div>

            <div className="mt-2 bettingList">
                        <div className="align-items-cente col-16 text-end my-autor"> {/* 열을 나누고 select를 추가하는 부분 */}
                            <div className="btn-group">
                                <button type="button" className="btn btn-success">스포츠</button>
                                <button type="button" className="btn btn-success">라이브</button>
                                <button type="button" className="btn btn-success">미니게임</button>
                                <button type="button" className="btn btn-success">가상게임</button>
                                <button type="button" className="btn btn-success">로투스게임</button>
                            </div>
                        </div>
                        <div className="mt-1">
                            <div className="col-16 text-end my-auto">
                                <div className="d-flex align-items-center justify-content-end mb-2"> {/* 열을 나누고 select를 추가하는 부분 */}
                            <select
                                className="form-select bg-dark text-light"
                                style={{
                                    width: "100px",
                                    height: "30px", // 세로 크기 조정
                                    borderColor: "#008080",
                                    fontSize: "13px" // 글자 크기 조정
                                }}
                                value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                onChange={(e) => {
                                    dispatch(getBettingListReducer({
                                        page: 1,
                                        pageSize: parseInt(e.target.value),
                                        order: initialState.order,
                                        userNo: userInfo.userNo,
                                    }))
                                }}
                            >
                                <option value={20}>20개씩</option>
                                <option value={50}>50개씩</option>
                                <option value={100}>100개씩</option>
                                <option value={200}>200개씩</option>
                                <option value={999}>모두</option>
                            </select>
                            <select
                                className="form-select bg-dark text-light"
                                style={{
                                    width: "135px",
                                    height: "30px", // 세로 크기 조정
                                    borderColor: "#008080",
                                    fontSize: "13px" // 글자 크기 조정
                                }}
                                value={initialState.order === 0? 0 : initialState.order}
                                onChange={e =>
                                    dispatch(getBettingListReducer({
                                        page: 1,
                                        pageSize: initialState.pageSize,
                                        order: parseInt(e.target.value),
                                        userNo: userInfo.userNo,
                                    }))
                                }
                            >
                                <option value={0}>경기시간순</option>
                                <option value={1}>베팅접수시간순</option>
                            </select>
                                </div></div></div>
                <BettingListComponent initialState={initialState} reloadBetList={reloadBetList}/>
                <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    )
}
export default BettingListContainer;