import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {getServiceCenterReducer,setTitle,setCont} from "../../redux/reducers/menu3/serviceCenterReducer";
import Pagination from "../../components/common/Pagination";
import {getDepositReducer} from "../../redux/reducers/menu3/depositReducer";
const ServiceCenterComponent = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo);
    const initialState = useSelector((state) => state.serviceCenterReducer)
    const getHelpList = () => {
        if(initialState.helpList !== null) {
            dispatch(getServiceCenterReducer({
                page:initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                userNo: userInfo.userNo,
            }))
        }
    }
    useEffect(getHelpList, [dispatch])
    const onTitleHandler = (e) =>{
        dispatch(setTitle(e.currentTarget.value))
    }
    const onContHandler = (e) =>{
        dispatch(setCont(e.currentTarget.value))
    }

    const handlePageChange = (page) => {
        dispatch(getServiceCenterReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            userNo: userInfo.userNo,
        }))
    }
    const helpInsert = (e) => {
        axios.post('/user/help/insert', {
            title: initialState.title,
            cont: initialState.cont,
            userNo: userInfo.userNo,
            status: initialState.status
        }).then((res) => {
            alert(res.data.isInserted? "문의완료" : "문의실패")
            getHelpList()
        })
    }
    return (

        <div className="main">
            <div className="subtitle">
                고객센터 SERVICE_CENTER
            </div>
            <div>
                <div className="mt-1" >
                    <div className="col-16 text-end my-auto">
                        <div className="d-flex align-items-center justify-content-end mb-2"> {/* 열을 나누고 select를 추가하는 부분 */}
                            <select
                                className="form-select bg-dark text-light"
                                style={{
                                    width: "100px",
                                    height: "33px", // 세로 크기 조정
                                    borderColor: "#008080",
                                    fontSize: "13px" // 글자 크기 조정
                                }}
                                value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                onChange={(e) => {
                                    dispatch(getServiceCenterReducer({
                                        page: 1,
                                        pageSize: parseInt(e.target.value),
                                        order: initialState.order,
                                        userNo: userInfo.userNo,
                                    }))
                                }}
                            >
                                <option value={5}>5개씩</option>
                                <option value={10}>10개씩</option>
                                <option value={20}>20개씩</option>
                            </select>
                            <select
                                className="form-select bg-dark text-light ml-2"
                                style={{
                                    width: "135px",
                                    height: "33px", // 세로 크기 조정
                                    borderColor: "#008080",
                                    fontSize: "13px" // 글자 크기 조정
                                }}
                                value={initialState.order === 0? 0 : initialState.order}
                                onChange={e =>
                                    dispatch(getServiceCenterReducer({
                                        page: 1,
                                        pageSize: initialState.pageSize,
                                        order: parseInt(e.target.value),
                                        userNo: userInfo.userNo,
                                    }))
                                }
                            >
                                <option value={0}>문의시간순</option>
                                <option value={1}>답변시간순</option>
                            </select>
                        </div>
                    </div>
                    <table className="table table-bordered table-sm text-center align-middle helpTitle">
                        <thead className="table-dark">
                        <tr>
                            <th>문의내용</th>
                            <th style={{width: "80px"}}>상태</th>
                        </tr>
                        </thead>
                        {initialState.helpList.map((help, index) => (
                            <tbody key={help.no}>
                            <tr>
                                <td>
                                    <Link className="nav-link" data-bs-toggle="collapse" to={"#collapse_helplist" + index}
                                          aria-expanded="false" aria-controls={"collapse_helplist" + index}>
                                        {help.title}
                                    </Link>
                                </td>
                                <td>
                                    {
                                        {
                                            1 : "답변대기",
                                            2 : "답변완료"
                                        }[help.status]
                                    }
                                </td>

                            </tr>

                            <tr className="collapse" id={"collapse_helplist" + index}>
                                <td colSpan="4">
                                    <div style={{
                                        textAlign: "left"
                                    }}>
                                        <div><p> 문의내용 : {help.cont}</p></div>
                                        <div style={{
                                            borderTop: "1px solid black"
                                        }}>
                                            {help.help_replies.map((help_reply, index) => (
                                                <p key={help_reply.no}>
                                                    답변내용 : {help_reply.cont}</p>
                                            ))}</div>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>

                <div className="d-flex justify-content-end">
                    <button className="btn btn-outline-secondary me-2 button1" type="button"
                            data-bs-toggle="collapse" data-bs-target="#addCollapse"
                            aria-expanded="false" aria-controls="addCollapse">문의하기
                    </button>
                </div>

                <div id="addCollapse" className="add collapse" style={{color: "white"}}>
                    <div className="my-3 d-flex">
                        <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                            width: "50px",
                            marginTop: "5px"
                        }}>제목</label>
                        <input type="text" maxLength="20" className="form-control" value={initialState.title} id="exampleFormControlInput1" onChange={onTitleHandler}/>
                    </div>
                    <div className="mb-3 d-flex">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                            width: "50px"
                        }}>내용</label>
                        <textarea className="form-control" maxLength="2000" value={initialState.cont} id="exampleFormControlTextarea1" rows="3" onChange={onContHandler}></textarea>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-secondary" type="button"
                                id="button-addon2"
                                onClick={helpInsert}
                        >내용등록
                        </button>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-2"> {/* text-center 클래스 추가 */}
                <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
            </div>
        </div>

    )
}

export default ServiceCenterComponent;