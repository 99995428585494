import {call, put} from "redux-saga/effects";
import {fetchBettingList} from "../../../lib/api";
import {failedGetBettingListReducer, setBettingListReducer} from "../../reducers/menu1/bettingListReducer";

export function* getBettingListSaga(action) {
    console.log("##getBettingListSaga##");
    try{
        const res = yield call(fetchBettingList,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.userNo);
        yield put(setBettingListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetBettingListReducer(error))
    }
}
