import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import commonInfo from "../../config/commonInfo";

const Event = ({event, onRefresh}) => {

    const [eventList, setEventList] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);

    const getEventList = () => {
        axios.get('/user/event/get_event_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            setEventList(res.data.eventList)
        })
    }
    useEffect(getEventList, [])
    return (
        <div className="main">
            <div className="subtitle">
                이벤트 EVENT
            </div>
            <div className="eventd1 mt-2">
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-dark">
                    <tr>
                        <td>이벤트</td>
                    </tr>
                    </thead>
                    {event.map((event, index) => (
                    <tbody key={event.no}>
                        <tr>
                            <td className="eventTitle" >
                                <Link className="nav-link" data-bs-toggle="collapse" to={"#collapse" + index}
                                      aria-expanded="false" aria-controls={"collapse" + index}>
                                    {event.title}
                                </Link>
                            </td>

                        </tr>
                        <tr className="collapse" id={"collapse" + index}>
                            <td className="eventContent">
                                {event.cont}
                                <br></br>
                                <img src={commonInfo.imgUrl + event.image} alt="" />
                            </td>

                        </tr>
                    </tbody>
                    ))}
                </table>
            </div>
        </div>


    )
}
export default Event;