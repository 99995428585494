import {call, put, take} from "redux-saga/effects"
import {completedBet, failedBet} from "../reducers/betCart"
import {bet} from "../../lib/api";

export function* betSaga(action) {
    try {
        const res = yield call(bet, action.payload.selectedList, action.payload.money, action.payload.userNo)
        yield put(completedBet(res.data))

    } catch(error) {
        console.log(error)
        yield failedBet(error);
    }
}
