import React, {useEffect, useState} from 'react';
import axios from "axios";

const Lost = () => {

    const [id, setId] = useState("");
    const [nickName, setNickName] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [ip, setIp] = useState("");
    const [country, setCountry] = useState("");
    const domainName = window.location.hostname;
    const getIp = () => {
        axios.get('https://api.ip.pe.kr/json/')
            .then((res) => {
                console.log(res.data)
                setIp(res.data.ip)
                setCountry(res.data.country_name.ko)
            })
    }

    useEffect(getIp, [])
    const onIdHandler = (e) => {
        setId(e.currentTarget.value);
    }
    const onNickNameHandler = (e) => {
        setNickName(e.currentTarget.value);
    }
    const onAccountHolderHandler = (e) => {
        setName(e.currentTarget.value);
    }
    const onPhoneNumberHandler = (e) => {
        setPhone(e.currentTarget.value);
    }

    const onLostHandler = (e) => {
        axios.post('/user/lost/insert', {
            id: id,
            nickname: nickName,
            name: name,
            domain: domainName,
            phone: phone,
            ip: ip,
            country: country
        }).then((res) => {
            if(res.data.isInserted){
                alert("아이디 : " + id + "\n닉네임 : " + nickName + "\n성함 : " + name + "\n연락처 : " + phone + "\n문의를 접수했습니다. ")

                window.location.href = "/"
            } else {
                alert('취소했습니다.')
            }

        })
    }

    return(
        <div className="main">
            <div className="subtitle">
                    분실문의
            </div>
            <div className="mt-2 lost">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>아이디</label>
                    <input type="text" maxlength="12" value={id} onChange={onIdHandler} />

                    <label>닉네임</label>
                    <input type="text" maxlength="7" value={nickName} onChange={onNickNameHandler} />

                    <label>성함</label>
                    <input type="text" maxlength="12" value={name} onChange={onAccountHolderHandler} />

                    <label>연락처</label>
                    <input type="text" maxlength="13" value={phone} onChange={onPhoneNumberHandler} />
                </div>
                <div className="text-center mt-3">
                    <button onClick={onLostHandler}>문의 전송</button>
                </div>
            </div>
        </div>

    )
}
export default Lost;