module.exports = {
    depositType: (type) => {
        switch(type) {
            case 0 :
                return "스포츠"
            case 1 :
                return "카지노"
            case 2 :
                return "미니게임"
        }
    },
    depositState: (type) => {
        switch(type) {
            case 0 :
                return "신청"
            case 1 :
                return "대기"
            case 2 :
                return "완료"
            case 3 :
                return "취소"
        }
    }
}