import React from 'react';
import MemoContainer from "../../containers/menu1/MemoListContainer";


const Memo = () => {
    return (
        <div>
            <MemoContainer />
        </div>
    );
};

export default Memo;