import React from 'react';
import {Link, Outlet} from 'react-router-dom'

const main = () => {
    return (
        <div className="container-fluid">
            <Outlet />
        </div>
    )
}

export default main;