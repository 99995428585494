import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link, useLocation, Outlet, Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import typeCase from "../../util/typeCase"
import { NumericFormat } from 'react-number-format';
import AddBetComponent from "../../components/addBetComponent";
import common from "../../util/common";
import {addCart} from "../../redux/reducers/betCart";

const Sports = () => {
    const dispatch = useDispatch()
    const [sports, setSports] = useState("sports");
    const [matches, setMatches] = useState([]);
    const [fixtureId, setFixtureId] = useState("");
    const [fixtures, setFixtures] = useState([]);

    const sportsHandler = (e) => {
        setSports("sports")
    }
    const liveHandler = (e) => {
        setSports("live")
    }

    const getActiveMatchList = () => {
        axios.get('/user/match/getActiveMatch', {

        }).then((res) => {
            const ratioArray = res.data.result;

            const groupByFix = common.groupBy(ratioArray, "fixture_id");
            const fixId = Object.keys(groupByFix);

            const array = [];
            const fixtures = []
            for(let i = 0; i < fixId.length; i++) {
                fixtures.push(groupByFix[fixId[i]][0])
                //array.push(groupByFix[fixId[i]])
            }
            fixtures.sort((a, b) => {
                const ddd = moment(a.start_date).unix() - moment(b.start_date).unix()
                return ddd;
            })
            const fixIds = []
            for(let i = 0; i < fixtures.length; i++) {
                fixIds.push(fixtures[i].fixture_id)
            }

            for(let i = 0; i < fixIds.length; i++) {
                array.push(groupByFix[fixIds[i]])
            }

            setMatches(ratioArray)
            if(fixId.length > 0) {
                setFixtureId(fixId[0])
            }
            setFixtures(array)

            console.log("##matches##",matches);
            console.log("##fixtures##",fixtures);
        })
    }

    useEffect(getActiveMatchList, [])


    const onSelectHandler = (ratio, betNo, e) => {
        //TODO 시간확인
        const _ratio = Object.assign({}, ratio)
        _ratio.bet_no = betNo
        dispatch(addCart(_ratio))
    }
    function gameType(market) {
        switch (market) {
            case "1X2" : return "승무패"
            case "Asian Handicap" : return "핸디캡"
            case "Under/Over" : return "오버언더"
            case "Correct Score" : return "정확한 스코어"
        }
    }

    function addBet() {
        if(matches.length > 0 && fixtureId !== "") {

            const groupByFix = common.groupBy(matches, "fixture_id");

            if (groupByFix[fixtureId].length > 0) {
                console.log(groupByFix[fixtureId])
                const groupByMarketId = common.groupBy(groupByFix[fixtureId], "market_id")
                const marketIds = Object.keys(groupByMarketId);
                console.log(groupByMarketId[marketIds[0]])
                return (
                    <>

                        <div className="mx-3 addBetList" style={{background: "#333333"}}>
                            <div className="league">
                                <div className="leagueName">{groupByMarketId[marketIds[0]][0].sport}//{groupByMarketId[marketIds[0]][0].league}</div>
                            </div>
                            <div className="addBetTeamName">
                                <div>경기화면 ({groupByMarketId[marketIds[0]][0].home} {moment(groupByMarketId[marketIds[0]][0].start_date).format("YYYY-MM-DD HH:mm")} {groupByMarketId[marketIds[0]][0].away})</div>
                            </div>
                        </div>
                        <div className="mx-3 itemSelect">
                            <button>전체 <span style={{color: "coral"}}>12</span></button>
                            <button>승패 3</button>
                            <button>핸디캡 3</button>
                            <button>언더오버 3</button>
                            <button>조합 2</button>
                            <button>기타 1</button>
                        </div>
                        {marketIds.map((marketId, mIndex) => (
                            <>
                                {marketId !== "1" && (
                                    <div className="mx-3 addBetList">
                                        {groupByMarketId[marketId].map((ratio, index) => (
                                            <>
                                                {index === 0 && (
                                                    <>
                                                        <div className="ms-2 d-flex justify-content-start">
                                                            <div>{gameType(ratio.market)}</div>
                                                        </div>
                                                        {ratio.market_id !== 6 && (
                                                            <div className="d-flex text-center">
                                                                <label className="addTitle2">&nbsp;</label>
                                                                <label className="addTitle1">
                                                                    <span>{ratio.home}</span>
                                                                </label>
                                                                <label className="addTitle3">
                                                                    <span>{ratio.away}</span>
                                                                </label>
                                                            </div>
                                                        )
                                                        }
                                                    </>
                                                )}

                                                {ratio.market_id === 3 &&
                                                    (
                                                        <div className="form-check form-check-inline d-flex justify-content-between">
                                                            <label className="addHandicapItemSelect2">
                                                                <span style={{float: "left"}}>H</span>
                                                                <span style={{color: "lightgreen", float: "right"}}>
                                                                    {ratio.market_baseline.replace(" (0-0)", "")}
                                                                </span>
                                                            </label>

                                                            <label className="btn btn-secondary addHandicapItemSelect1" htmlFor={"btn1radio" + index}
                                                                   onClick={() => {onSelectHandler(ratio, 1)}}>
                                                                <span style={{color: "coral"}}>{ratio.bet1}</span>
                                                            </label>

                                                            <label className="btn btn-secondary addHandicapItemSelect3" htmlFor={"btn3radio" + index}
                                                                   onClick={() => {onSelectHandler(ratio, 3)}}>
                                                                <span style={{color: "coral"}}>{ratio.bet3}</span>
                                                            </label>
                                                        </div>
                                                    )}
                                                {ratio.market_id === 2 &&
                                                    (
                                                        <div className="form-check form-check-inline d-flex justify-content-between">
                                                            <label className="addOUItemSelect2">
                                                                <span style={{float: "left"}}>O/U</span>
                                                                <span style={{color: "lightgreen", float: "right"}}>{ratio.bet2}</span>
                                                            </label>

                                                            <label className="btn btn-secondary addOUItemSelect1" htmlFor={"btn1radio" + index}
                                                                   onClick={() => {onSelectHandler(ratio, 1)}}>
                                                                <span style={{color: "coral"}}>{ratio.bet1}</span>
                                                            </label>


                                                            <label className="btn btn-secondary addOUItemSelect3" htmlFor={"btn3radio" + index}
                                                                   onClick={() => {onSelectHandler(ratio, 3)}}>
                                                                <span style={{color: "coral"}}>{ratio.bet3}</span>
                                                            </label>
                                                        </div>
                                                    )}
                                                {ratio.market_id === 6 &&
                                                    (
                                                        <label className="btn btn-secondary mt-1 exactScore" onClick={() => {onSelectHandler(ratio, 1)}}>
                                                            <span style={{float: "left",}}>{ratio.bet2}</span>
                                                            <span style={{color: "coral", float: "right"}}>{ratio.bet1}</span>
                                                        </label>

                                                    )}
                                            </>
                                        ))}
                                    </div>
                                )}
                            </>
                        ))}
                    </>
                )
            } else {
                return (null)
            }
        }
    }

    function getFixtureMatch(ratios) {
        const groupByMarketId = common.groupBy(ratios, "market_id");
        const marketIds = Object.keys(groupByMarketId);

        return (
            <>
                {marketIds.map((marketId, mIndex) => (
                    <div>
                        {marketId === "1" && groupByMarketId[marketId].map((ratio, index) => (
                            <div className="d-flex justify-content-between px-3">
                                <label className="btn btn-secondary itemSelect1" onClick={() => {
                                    onSelectHandler(ratio, 1)
                                }}>
                                    <span style={{float: "left",}}>홈 승</span>
                                    <span style={{
                                        color: "coral",
                                        float: "right"
                                    }}>{ratio.bet1}</span>
                                </label>

                                {ratio.bet2_id !== null && (
                                    <label className="btn btn-secondary itemSelect2"
                                           onClick={() => {
                                               onSelectHandler(ratio, 2)
                                           }}>
                                        <span style={{float: "left"}}>무</span>
                                        <span style={{
                                            color: "coral",
                                            float: "right"
                                        }}>{ratio.bet2}</span>
                                    </label>
                                )
                                }
                                <label className="btn btn-secondary itemSelect3" onClick={() => {
                                    onSelectHandler(ratio, 3)
                                }}>
                                                                <span style={{
                                                                    color: "coral",
                                                                    float: "left"
                                                                }}>{ratio.bet3}</span>
                                    <span style={{float: "right"}}>원정 승</span>
                                </label>
                            </div>
                        ))}
                        {["2", "3"].includes(marketId) && groupByMarketId[marketId].length > 1 && (
                            <div className="accordion" id={"accordion_" + groupByMarketId[marketId][0].bet1_id}>
                                <div className="accordion-item" style={{
                                    backgroundColor: "transparent"
                                }}>
                                    <div className="d-flex justify-content-between px-3"
                                         style={{
                                             display: "flex"
                                         }}>

                                        <label className="btn btn-secondary itemSelect1" onClick={() => {
                                            onSelectHandler(groupByMarketId[marketId][0], 1)
                                        }}>
                                            <span style={{float: "left",}}>{(marketId === "3"? "홈 승" : "오버")}</span>
                                            <span style={{
                                                color: "coral",
                                                float: "right"
                                            }}>{groupByMarketId[marketId][0].bet1}</span>
                                        </label>
                                        <button className="accordion-button collapsed itemSelect2 p-0" type="button"  style={{
                                            backgroundColor: "transparent"
                                        }}
                                                data-bs-toggle="collapse"
                                                data-bs-target={"#collapse_" + groupByMarketId[marketId][0].bet1_id}
                                                aria-expanded="false"
                                                aria-controls={"collapse_" + groupByMarketId[marketId][0].bet1_id} >
                                            <div style={{
                                                width: "100%",
                                                paddingLeft: "15px",
                                                paddingRight: "5px",
                                            }}>
                                                <span style={{color: "white", float: "left"}}>{marketId === "3"? "H": "O/U"}</span>
                                                <span style={{
                                                    color: "lightgreen",
                                                    float: "right"
                                                }}>{groupByMarketId[marketId][0].market_baseline.replace(" (0-0)", "")} </span>
                                            </div>

                                        </button>
                                        <label className="btn btn-secondary itemSelect3" onClick={() => {
                                            onSelectHandler(groupByMarketId[marketId][0], 3)
                                        }}>
                                                            <span style={{
                                                                color: "coral",
                                                                float: "left"
                                                            }}>{groupByMarketId[marketId][0].bet3}</span>
                                            <span style={{float: "right"}}>{(marketId === "3"? "원정 승" : "언더")}</span>
                                        </label>


                                    </div>
                                    <div id={"collapse_" + groupByMarketId[marketId][0].bet1_id}
                                         className="accordion-collapse collapse"
                                         data-bs-parent={"#accordion_" + groupByMarketId[marketId][0].bet1_id}>
                                        <div className="accordion-body p-0" style={{
                                            backgroundColor: "transparent"
                                        }}>
                                            {groupByMarketId[marketId].map((ratio, index) => (
                                                <>
                                                    {index !== 0 && (
                                                        <div style={{
                                                            backgroundColor: "#222222"
                                                        }}
                                                             className="d-flex justify-content-between px-3">
                                                            <label className="btn btn-secondary itemSelect1" onClick={() => {
                                                                onSelectHandler(ratio, 1)
                                                            }}>
                                                                <span style={{float: "left",}}>{(marketId === "3"? "홈 승" : "오버")}</span>
                                                                <span style={{
                                                                    color: "coral",
                                                                    float: "right"
                                                                }}>{ratio.bet1}</span>
                                                            </label>

                                                            <div className="itemSelect2"  style={{
                                                                backgroundColor: "transparent",
                                                                paddingLeft: "15px",
                                                                paddingRight: "25px",
                                                                alignSelf: "center",
                                                            }}>
                                                                <span style={{color: "white", float: "left"}}>{marketId === "3"? "H": "O/U"}</span>
                                                                <span style={{
                                                                    color: "lightgreen",
                                                                    float: "right"
                                                                }}>{ratio.market_baseline.replace(" (0-0)", "")}</span>
                                                            </div>
                                                            <label className="btn btn-secondary itemSelect3" onClick={() => {
                                                                onSelectHandler(ratio, 3)
                                                            }}>
                                                                <span style={{
                                                                    color: "coral",
                                                                    float: "left"
                                                                }}>{ratio.bet3}</span>
                                                                <span style={{float: "right"}}>{(marketId === "3"? "원정 승" : "언더")}</span>
                                                            </label>
                                                        </div>
                                                    )}

                                                </>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                        {["2", "3"].includes(marketId) && groupByMarketId[marketId].length === 1 && groupByMarketId[marketId].map((ratio, index) => (
                            <div
                                className="d-flex justify-content-between px-3">
                                <label className="btn btn-secondary itemSelect1" onClick={() => {
                                    onSelectHandler(ratio, 1)
                                }}>
                                    <span style={{float: "left",}}>{(marketId === "3"? "홈 승" : "오버")}</span>
                                    <span style={{
                                        color: "coral",
                                        float: "right"
                                    }}>{ratio.bet1}</span>
                                </label>

                                <div className="itemSelect2"  style={{
                                    backgroundColor: "transparent",
                                    paddingLeft: "15px",
                                    paddingRight: "25px",
                                    alignSelf: "center",
                                }}>
                                    <span style={{color: "white", float: "left"}}>{marketId === "3"? "H": "O/U"}</span>
                                    <span style={{
                                        color: "lightgreen",
                                        float: "right"
                                    }}>{ratio.market_baseline.replace(" (0-0)", "")}</span>
                                </div>
                                <label className="btn btn-secondary itemSelect3" onClick={() => {
                                    onSelectHandler(ratio, 3)
                                }}>

                                    <span style={{float: "right"}}>{(marketId === "3"? "원정 승" : "언더")}</span>
                                    <span style={{
                                        color: "coral",
                                        float: "left"
                                    }}>{ratio.bet3}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                ))}
            </>
        )
    }

    return (
        <div className="main">
            <div className="subtitle">
                스포츠
            </div>
            <div className="d-flex">
                <div className="border multistage1 pe-1">
                    <div className=" multistage1_1">

                        {
                            fixtures.map((ratios, fIndex) => (
                                <div className=" gameList" id={"div_"+fIndex}>
                                    <div className="league">
                                        <div className="leagueName" style={{fontSize: "13px"}}>
                                            <img className="my-auto"
                                                 src={process.env.PUBLIC_URL + "/game/basketBall.png"} style={{
                                                width: "20px",
                                                height: "20px"
                                            }}/>
                                            <span className="mx-1">{ratios[0].sport + " " + ratios[0].league}</span>
                                            <img className="my-auto" src={process.env.PUBLIC_URL + "/game/kbl.png"}
                                                 style={{
                                                     width: "23px",
                                                     height: "23px"
                                                 }}/>
                                        </div>
                                    </div>
                                    <div className="gameDetails">
                                        <div style={{
                                            display: "flex",
                                            borderBottom: "1px solid #787878",
                                            fontSize: "13px"
                                        }}>
                                            <div className="col-9 gameTime">
                                                {moment(ratios[0].start_date).format("YYYY-MM-DD HH:mm")}
                                            </div>
                                            <div className="col  my-auto">
                                                <button className="button1">★</button>
                                                <button className="ms-2 button2" onClick={() => {
                                                    setFixtureId(ratios[0].fixture_id)
                                                }}>+{ratios.length}</button>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="teamName">
                                                <img className="my-auto"
                                                     src={process.env.PUBLIC_URL + "/game/samsung.png"} style={{
                                                    width: "20px",
                                                    height: "20px"
                                                }}/>
                                                <span className="mx-1">{ratios[0].home}</span>
                                                <span className="mx-2">vs</span>
                                                <img className="my-auto"
                                                     src={process.env.PUBLIC_URL + "/game/mobis.png"} style={{
                                                    width: "23px",
                                                    height: "23px"
                                                }}/>
                                                <span className="mx-1">{ratios[0].away}</span>
                                            </div>
                                            {getFixtureMatch(ratios)}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="border multistage2 pe-1">
                    <div className="multistage2_1">
                        <div className="multistage2_1">
                            <div className="addBet mt-3 text-center">
                                <div>추가배팅</div>
                            </div>
                            {addBet()}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height: "30px"}}></div>

        </div>
    )
}
export default Sports;