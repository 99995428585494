import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage"
import userInfoReducer from "./userInfo"
import betCartReducer from "./betCart"
import bettingListReducer from "./menu1/bettingListReducer"
import gameResultReducer from "./menu1/gameResultReducer"
import depositReducer from "./menu3/depositReducer"
import withdrawReducer from "./menu3/withdrawReducer"
import serviceCenterReducer from "./menu3/serviceCenterReducer"
import eventReducer from "./menu1/event"
import faqReducer from "./menu1/faq"
import noticeReducer from "./menu1/notice"
import memoReducer from "./menu1/memo"
import attendReducer from "./menu1/attend"
import {persistReducer} from "redux-persist";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userInfo"]
}

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    betCart: betCartReducer,
    bettingListReducer: bettingListReducer,
    gameResultReducer: gameResultReducer,
    depositReducer: depositReducer,
    withdrawReducer: withdrawReducer,
    serviceCenterReducer: serviceCenterReducer,
    event: eventReducer,
    faq: faqReducer,
    notice: noticeReducer,
    memo: memoReducer,
    attend: attendReducer,
});

export default persistReducer(persistConfig, rootReducer);