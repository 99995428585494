import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../redux/reducers/userInfo"
import {persistor} from "../../index";

const Login = () => {

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo)

    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [ip, setIp] = useState("");
    const [country, setCountry] = useState("");
    const [devices, setDevices] = useState("");
    const domainName = window.location.hostname;
    const userAgent = navigator.userAgent;


    const getDevice = () => {
        axios.get('https://api.ip.pe.kr/json/')
            .then((res) => {
                let checkDevice = "";
                if (userAgent.indexOf("Android") !== -1) {
                    checkDevice = 1
                } else if (userAgent.indexOf("iPhone") !== -1) {
                    checkDevice = 2
                } else {
                    checkDevice = 0
                }
                setDevices(checkDevice)
                setIp(res.data.ip)
                setCountry(res.data.country_name.ko)

            })
    }
    useEffect(getDevice, [])

    const onIdHandler = (e) => {
        const currentId = e.currentTarget.value
        setId(currentId);
        const notKorean = /[ㄱ-ㅎ가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
        if (notKorean.test(currentId)) {
            alert("영어,숫자조합만 가능합니다")
            setId("")
        }
    }

    const onPwHandler = (e) => {
        const currentPw = e.currentTarget.value
        setPw(currentPw);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentPw)) {
            alert("영어,숫자조합만 가능합니다")
            setPw("")
        }
    }

    const onSubmitHandler = (e) => {
        if (id === "") {
            alert("아이디를 입력해주시기 바랍니다")
        } else if (pw === "") {
            alert("비밀번호를 입력해주시기 바랍니다")
        } else {
            axios.post('/user/member/login', {
                id: id,
                pw: pw,
                domain: domainName,
                ip: ip,
                country: country,
                device: devices,
                success: 1
            }).then((res) => {
                console.log(res.data)
                if (res.status === 200) {
                    dispatch(setUserInfo(res.data))
                    window.location.href = "/"
                }

            })
        }
    }
    const inputPress = (e) => {
        if (e.key === "Enter") {
            onSubmitHandler(e.target.value)
        }
    }

    return (
        <div className="main">
            <div className="subtitle">
                로그인 LOGIN
            </div>
            <div className="text-center" style={{
                background: "#333333",
                padding: "20px"
            }}>
                <div style={{
                    width: "100%",
                    color: "white"
                }}> 로그인후 이용하시기 바랍니다.
                </div>

                <div className="mt-2 border-bottom border-white text-white">
                    <div className="mt-3 ">
                        <form className="form-inline justify-content-center d-flex" onSubmit={onSubmitHandler} >
                            <div className="col-3">
                                <label>아이디</label>
                            </div>
                            <div className="col-3">
                                <input type="text" maxlength="12" value={id} onChange={onIdHandler} placeholder="아이디" style={{
                                    width: "200px",
                                    background: "black",
                                    color: "white",
                                }}/>
                            </div>
                            <div className="col-3">
                                <label>비밀번호</label>
                            </div>
                            <div className="col-3">
                                <input type="password" value={pw} onChange={onPwHandler} onKeyDown={inputPress} placeholder="비밀번호" style={{
                                    width: "200px",
                                    background: "black",
                                    color: "white",
                                }}/>
                            </div>
                        </form>
                    </div>

                    <div className="text-center mt-3">
                        <button className="loginAreaButton" onClick={onSubmitHandler}>로그인</button>
                        <button className="loginAreaButton" style={{marginLeft: "20px"}} onClick={join}>회원가입</button>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default Login;
function join(e) {
    window.location.href = "/sign/codeCheck"
}