import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const LiveCasino = () => {
    return (
        <div className="main">
            <div className="subtitle">
                라이브카지노 LIVE CASINO
            </div>
        </div>
    )
}
export default LiveCasino;