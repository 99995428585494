import React, {useEffect, useState} from 'react';
import moment from "moment";
import axios from "axios";
import commonInfo from "../../config/commonInfo";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const Attend = ({attend, attendChkDate, totalCount, onRefresh}) => {

    const [attendList, setAttendList] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);

    const [attendCount, setAttendCount] = useState(0);


    const dailyCheck = (attendDate) => {

        if( totalCount === 0 )
        {
            return;
        }
        else
        {
            /*
            [출석체크 로직 삽입]
             - deposit 테이블 : 충전해주면 되는지, 충전하면 포인트 기준은 무엇인지?
             - attends 테이블 : attendance_check 누적+1, updateAt 최신일시로 갱신
            */
            //alert("출석체크##### :: "+userInfo.userNo);
            axios.get('/user/attend/update?userNo='+userInfo.userNo+"&attendDate="+attendDate, {
                userNo: userInfo.userNo,
                attendDate: attendDate
            }).then((res) => {
                alert(res.data.success? "출석체크완료" : "출석체크실패")
                onRefresh()
            })


            /*
            axios.get('/user/attend/update',{
                userNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.success? "출석체크완료" : "출석체크실패")
                onRefresh()
            })
            */



        }
    }


    function Calendar () {
        const [getMoment, setMoment] = useState(moment());
        const today = getMoment
        const firstWeek = today.clone().startOf('month').week();
        const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();



        const weekArr = ["일", "월", "화", "수", "목", "금", "토"]; //일주일
        const calendarArr=(attendDate)=>{


            //alert("today.format('YYYYMMDD') : "+today.format('YYYYMMDD') );
            //alert("attendDate.indexOf(today) >> "+attendDate.indexOf(today));

            let result = [];
            let week = firstWeek;
            for ( week; week <= lastWeek; week++) {
                result = result.concat(

                    <tr key={week}>
                        {
                            Array(7).fill(0).map((data, index) => {
                                let days = today.clone().startOf('year').week(week).startOf('week').add(index, 'day'); //d로해도되지만 직관성
                                if (moment().format('YYYYMMDD') === days.format('YYYYMMDD') && totalCount > 0  ) {
                                    return (
                                        <td className="today" key={index} style={{
                                            backgroundImage: `url(${process.env.PUBLIC_URL + "/test/attendCheck.png"})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "80% 90%",
                                            backgroundPosition: "center",
                                            cursor:"pointer"
                                        }}
                                            onClick={() => dailyCheck(attendChkDate+","+days.format('YYYYMMDD'))}
                                        >

                                            <span>{days.format('D')}</span>
                                        </td>
                                    );
                                } else if (days.format('MM') !== today.format('MM')) {
                                    return (
                                        <td className="emptyDay" key={index} >
                                            <span>{days.format('D')}</span>
                                        </td>
                                    );
                                }
                                else if ( attendChkDate != null && attendChkDate.indexOf(days.format('YYYYMMDD')) > -1  ) {

                                    return (
                                        <td className="today" key={index} style={{
                                            backgroundImage: `url(${process.env.PUBLIC_URL + "/test/attendCompleted.png"})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "80% 90%",
                                            backgroundPosition: "center",
                                            cursor:"pointer"
                                        }}
                                        >

                                            <span>{days.format('D')}</span>
                                        </td>
                                    );
                                }
                                else {
                                    return (
                                        <td className="day" key={index} style={{
                                            backgroundImage: `url(${process.env.PUBLIC_URL + "/test/attendNotCheck.png"})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "80% 90%",
                                            backgroundPosition: "center"
                                        }}
                                        >
                                            <span>{days.format('D')}</span>
                                        </td>
                                    );
                                }
                            })
                        }
                    </tr>
                );
            }
            return result;
        }


        return (
            <div className="align-items-center">
                <div className="title d-flex justify-content-between">
                    <div className="caution">
                        <span>▶당일 50,000원이상 충전하셔야 출석체크가 가능합니다.</span>
                    </div>
                    <div className="todayAttend">
                        <span>{today.format('YYYY 년 MM 월 DD일')} 출석체크</span>
                    </div>
                    <div className="month ps-5">
                        <button onClick={()=>{ setMoment(getMoment.clone().subtract(1, 'month')) }} >◀</button>
                        <span>{today.format('YYYY 년 MM 월')}</span>
                        <button onClick={()=>{ setMoment(getMoment.clone().add(1, 'month')) }} >▶</button>
                    </div>
                </div>
                <div className="align-items-center calendar ">
                    <table className="attendCalendar">
                        <tbody>
                        <tr className="week">
                            {weekArr.map((week) =>(
                                <td key={week.no}>{week}</td>
                            ))}
                        </tr>


                        {calendarArr("20230826,20230825")}

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    return (
        <div className="main">
            <div className="subtitle">
                출석체크 ATTEND CHECK
            </div>
            <div className="attendance">
                {Calendar()}
            </div>
        </div>
    )
}

export default Attend;

