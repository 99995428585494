import {call, put} from "redux-saga/effects";
import {fetchGameResult} from "../../../lib/api";
import {failedGetGameResultReducer, setGameResultReducer} from "../../reducers/menu1/gameResultReducer";

export function* getGameResultSaga(action) {
    console.log("##getGameResultSaga##");
    try{
        const res = yield call(fetchGameResult,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.userNo);
        yield put(setGameResultReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetGameResultReducer(error))
    }
}
