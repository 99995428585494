import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";
import {setUserInfo} from "../../redux/reducers/userInfo"
import {useSelector} from "react-redux";

const InPlay = () => {
    const userInfo = useSelector((state) => state.userInfo)

    return (
        <div className="main">
            <div className="subtitle">
                인플레이 IN-PLAY
            </div>
            {userInfo.isLogin?
                <div>1</div>
                :(<div>2</div>)}
        </div>
    )
}
export default InPlay;