import axios from "axios";

export const requestLogin = async (id, pw) => {
    return axios.post("/user/sign/login", {id: id, pw: pw})
}
export const getUserInfo = async (userNo) => {
    return axios.post("/user/member/get_user_info", {userNo: userNo})
}
export const bet = async (ratios, money, userNo) => {
    return axios.post("/user/bet/insert", {ratios: ratios, money: money, userNo: userNo})
}

export const fetchBettingList = async (page,pageSize,order,userNo) => {
    return axios.post("/user/bet/getList", {page:page ,pageSize:pageSize ,order:order,userNo: userNo})
}

export const fetchGameResult = async (page,pageSize,order,userNo) => {
    return axios.post("/user/bet/getGameResultList", {page:page ,pageSize:pageSize,order:order,userNo: userNo})
}

export const fetchDeposit = async (page,pageSize,order,userNo) => {
    return axios.post("/user/deposit/get_user_basic_info", {page:page ,pageSize:pageSize,order:order ,userNo: userNo})
}
export const fetchDepositCheckBankPw = async (no,name,bankPw) => {
    return axios.post("/user/deposit/check_bank_pw", {no:no ,name:name ,bankPw: bankPw})
}

export const fetchWithdraw = async (page,pageSize,order,userNo) => {
    return axios.post("/user/withdraw/get_user_basic_info", {page:page ,pageSize:pageSize,order:order,userNo: userNo})
}

export const fetchWithdrawCheckBankPw = async (no,name,bankPw) => {
    return axios.post("/user/withdraw/check_bank_pw", {no:no ,name:name ,bankPw: bankPw})
}

export const fetchServiceCenter = async (page,pageSize,order,userNo) => {
    return axios.post("/user/help/get_help_list", {page:page ,pageSize:pageSize,order:order,userNo: userNo})
}

export function fetchEvent(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/user/event/getEvent", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchFaq(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/user/faq/getFaq", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchNotice(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/user/notice/getNotice", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchMemo(page, pageSize, query, order, startDate, endDate, userNo) {
    return axios.get("/user/memo/getMemo", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
            userNo: userNo,
        }
    })
}

export function fetchAttend(page, pageSize, query, order, startDate, endDate, userNo) {
    return axios.get("/user/attend/getAttend", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
            userNo: userNo,
        }
    })
}
