import React from 'react';
import {Link, Outlet} from 'react-router-dom'

const Sign = () => {
    return (
        <div style={{
                width: "100%",
            }}><Outlet />
        </div>
    )
}

export default Sign;