import {Link, Outlet} from "react-router-dom";

const LoginModal = () => {

    return (
        <div className="modal_background">
            <div className="modal_box">
                <div className="buttonArea">
                    {/*<div className="closeButton" onClick={handleClose}>X</div>*/}
                    <div className="text-center">
                        <div>
                        <Link to="/">
                            <span style={{color: "white"}}>홈화면가기(이미지)</span>
                        </Link>
                        </div>
                        <div className="loginContent">
                            로그인후 이용 바랍니다.
                        </div>
                        <div className="login_join">
                            <button className="loginAreaButton" onClick={login} style={{width: "100px"}}>로그인</button>
                            <button className="loginAreaButton" onClick={join} style={{width: "100px", marginLeft: "20px"}}>회원가입</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function join(e) {
    window.location.href = "/sign/codeCheck"
}
function login(e) {
    window.location.href = "/sign/login"
}
export default LoginModal;