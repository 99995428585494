import React from 'react';
import FaqContainer from "../../containers/menu1/FaqListContainer";


const Faq = () => {
    return (
        <div>
            <FaqContainer />
        </div>
    );
};

export default Faq;