import {call, put} from "redux-saga/effects";
import {fetchServiceCenter} from "../../../lib/api";
import {failedGetServiceCenterReducer, setServiceCenterReducer} from "../../reducers/menu3/serviceCenterReducer";

export function* getServiceCenterSaga(action) {
    console.log("##getServiceCenterSaga##");
    try{
        const res = yield call(fetchServiceCenter,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.userNo);
        yield put(setServiceCenterReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetServiceCenterReducer(error))
    }
}
