import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    isLoading: false,
    selectedList: [],
    totalRatio: 0.0,
    money: 0,
    userNo: 0,
    error: null
}

export const betCartSlice = createSlice({
    name: 'betCart',
    initialState,
    reducers: {
        bet(state, action) {
            //TODO check time, status, rule etc...
            state.selectedList = action.payload.selectedList;
            state.money = action.payload.money;
            state.userNo = action.payload.userNo;
        },
        addCart(state, action) {
            //TODO check time, status, rule etc...
            //이미 카트에 담겼는지 확인
            const selectedItem = action.payload
            const beforeArray = state.selectedList.filter((item) => item.id === selectedItem.id)
            const index = state.selectedList.indexOf(beforeArray[0])
            if(index >= 0) {
                if(selectedItem.bet_no === state.selectedList[index].bet_no) {
                    state.selectedList = state.selectedList.filter((item) => item.id !== selectedItem.id);
                }
                else {
                    state.selectedList[index] = selectedItem
                }

            }
            else {
                state.selectedList.push(selectedItem)
            }
            let total = 0.0;
            for(let i = 0; i < state.selectedList.length; i++) {
                const _ratio = state.selectedList[i]
                let r;
                if(_ratio.bet_no === 1) {
                    r = _ratio.bet1
                }
                else if(_ratio.bet_no === 2) {
                    r = _ratio.bet2
                }
                else if(_ratio.bet_no === 3) {
                    r = _ratio.bet3
                }
                if(total === 0.0) {
                    total = r
                }
                else {
                    total *= r;
                }
            }
            state.totalRatio = total;

        },
        removeCart(state, action) {
            const selectedItem = action.payload.ratio
            const beforeArray = state.selectedList.filter((item) => item.id === selectedItem.id)
            const index = state.selectedList.indexOf(beforeArray[0])
            if(index >= 0) {
                state.selectedList = state.selectedList.filter((item) => item.id !== selectedItem.id);
            }
            let total = 0.0;
            for(let i = 0; i < state.selectedList.length; i++) {
                const _ratio = state.selectedList[i]
                let r;
                if(_ratio.bet_no === 1) {
                    r = _ratio.bet1
                }
                else if(_ratio.bet_no === 2) {
                    r = _ratio.bet2
                }
                else if(_ratio.bet_no === 3) {
                    r = _ratio.bet3
                }
                if(total === 0.0) {
                    total = r
                }
                else {
                    total *= r;
                }
            }
            state.totalRatio = total;
        },
        clearCart(state, action) {
            state.selectedList = [];
            state.totalRatio = 0.0;
            state.money = 0;
        },
        failedBet(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        completedBet(state, action) {
            state.isLoading = false;
            state.selectedList = [];
            state.totalRatio = 0.0;
        }
    }
})

export const { bet, addCart, removeCart, clearCart, failedBet, completedBet } = betCartSlice.actions
export default betCartSlice.reducer