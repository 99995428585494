import {createSlice} from "@reduxjs/toolkit"

export const gameResultReducerSlice = createSlice({
    name: 'gameResultReducer',
    initialState: {
        page:1,
        pageSize:20,
        order:0,
        totalCount : 0,
        isLoading: false,
        error: null,
        gameResultList : []
    },
    reducers: {
        getGameResultReducer(state, action) {
            console.log("#reducers getGameResultReducer start#");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.userNo = action.payload.userNo;
            state.order = action.payload.order;
        },
        setGameResultReducer(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            console.log("#reducers setGameResultReducer start#",action.payload.result,action.payload.totalCount);
            state.gameResultList = action.payload.result;
            state.totalCount  = action.payload.totalCount;

        },
        failedGetGameResultReducer(state, action) {
            console.log("#reducers failedGetGameResultReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const {
    getGameResultReducer,
    setGameResultReducer,
    failedGetGameResultReducer,
} = gameResultReducerSlice.actions;

export default gameResultReducerSlice.reducer;