import React from 'react';
import AttendContainer from "../../containers/menu1/AttendListContainer";


const Attend = () => {
    return (
        <div>
            <AttendContainer />
        </div>
    );
};

export default Attend;