import {createSlice} from "@reduxjs/toolkit"

export const withdrawReducerSlice = createSlice({
    name: 'withdrawReducer',
    initialState: {
        page: 1,
        pageSize: 5,
        order: 0,
        totalCount : 0,
        isLoading: false,
        error: null,
        name : "",
        money : 0,
        no : "",
        bankPw : "",
        withdrawList : [],
        account : null,
        isConfirm : false,
        account_no : "",
        account_owner :"",
        account_bank : "",
        account_accountNum : "",
        withdrawMoney : 0,
        pw : "",

    },
    reducers: {
        getWithdrawReducer(state, action) {
            console.log("#reducers getWithdrawReducer start#");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.userNo = action.payload.userNo;
            state.order = action.payload.order;
        },
        setWithdrawReducer(state, action) {
            state.isLoading = false;
            console.log("#reducers setWithdrawReducer start#",action.payload.money,action.payload.name,action.payload.withdrawList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.money = action.payload.money;
            state.name = action.payload.name;
            state.withdrawList = action.payload.withdrawList;
            state.account_bank = action.payload.bank;
            state.account_accountNum = action.payload.withdraw_account;
            state.totalCount  = action.payload.totalCount;
        },
        failedGetWithdrawReducer(state, action) {
            console.log("#reducers failedGetWithdrawReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        getWithdrawCheckBankPwReducer(state, action) {
            console.log("#reducers getWithdrawCheckBankPw start#",action.payload.no,action.payload.name,action.payload.bankPw);
            state.isLoading = true;
            state.no = action.payload.no;
            state.name = action.payload.name;
            state.bankPw = action.payload.bankPw;
        },
        setWithdrawCheckBankPwReducer(state, action) {
            state.isLoading = false;
            console.log("#reducers setWithdrawCheckBankPwReducer start#",action.payload.no,action.payload.owner,action.payload.bank,action.payload.accountNum,action.payload.isConfirm);
            state.account_no = action.payload.no;
            state.account_owner = action.payload.owner;
            state.account_bank = action.payload.bank;
            state.account_accountNum = action.payload.accountNum;
            state.isConfirm = action.payload.isConfirm;
        },
        failedGetWithdrawCheckBankPwReducer(state, action) {
            console.log("#reducers failedGetWithdrawCheckBankPwReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setWithdrawMoney(state, action) {
            console.log("#reducers setWithdrawMoney start#");
            state.isLoading = false;
            state.withdrawMoney = action.payload;
        },
        setPw(state, action) {
            console.log("#reducers setPw start#");
            state.isLoading = false;
            state.pw = action.payload;
        },


    }
});

export const {
    getWithdrawReducer,
    setWithdrawReducer,
    failedGetWithdrawReducer,
    getWithdrawCheckBankPwReducer,
    setWithdrawCheckBankPwReducer,
    failedGetWithdrawCheckBankPwReducer,
    setWithdrawMoney,
    setPw
} = withdrawReducerSlice.actions;

export default withdrawReducerSlice.reducer;