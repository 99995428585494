import {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {NumericFormat} from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../redux/reducers/userInfo"
import {persistor} from "../index";

const LoginComponent = () => {

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo)

    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [ip, setIp] = useState("");
    const [country, setCountry] = useState("");
    const [devices, setDevices] = useState("");
    const domainName =  window.location.hostname;
    const userAgent = navigator.userAgent;


    // const [casinoMoney, setCasinoMoney] = useState("");
    // const [game, setGame] = useState("");

    const getDevice = () => {
        axios.get('https://api.ip.pe.kr/json/')
            .then((res) => {
                let checkDevice = "";
                if ( userAgent.indexOf("Android") !== -1) {
                    checkDevice = 1
                } else if( userAgent.indexOf("iPhone") !== -1 ) {
                    checkDevice = 2
                } else {
                    checkDevice = 0
                }
                setDevices(checkDevice)
                setIp(res.data.ip)
                setCountry(res.data.country_name.ko)
            })
    }
    useEffect(getDevice, [])

    const onIdHandler = (e) => {
        const currentId = e.currentTarget.value
        setId(currentId);
        const notKorean = /[ㄱ-ㅎ가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
        if (notKorean.test(currentId)) {
            alert("영어,숫자조합만 가능합니다")
            setId("")
        }
    }

    const onPwHandler = (e) => {
        const currentPw = e.currentTarget.value
        setPw(currentPw);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentPw)) {
            alert("영어,숫자조합만 가능합니다")
            setPw("")
        }
    }

    const onSubmitHandler = (e) => {
        if(id === "") {
            alert("아이디를 입력해주시기 바랍니다")
        } else if(pw === "") {
            alert("비밀번호를 입력해주시기 바랍니다")
        } else {
            axios.post('/user/member/login', {
                id: id,
                pw: pw,
                domain: domainName,
                ip: ip,
                country: country,
                device: devices,
                success: 1
            }).then((res) => {
                //console.log(res.data)
                if(res.status === 200) {
                    dispatch(setUserInfo(res.data))
                    window.location.href = "/"
                }
            })
        }
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSubmitHandler(e.target.value)
        }
    }

    const purge = async () => {
        await persistor.purge();
    }

    const onLogoutHandler = (e) => {
        //TODO request Logout
        purge(
            alert("로그아웃")
        )

    }

    return (
        <div className="d-flex justify-content-end">
            {userInfo.isLogin?
                <div className="loginBox">
                    <div className="memberBox p-2" style={{fontSize: "14px"}}>
                        <div className="my-auto">{userInfo.user.nickname}님</div>
                        <div className="my-auto">보유머니 : <NumericFormat value={userInfo.user.money} displayType={'text'} thousandSeparator={true} /></div>
                        <div className="my-auto">카지노머니 : <NumericFormat value={0} displayType={'text'} thousandSeparator={true} /></div>
                        <div className="my-auto">포인트 : <NumericFormat value={userInfo.user.point} displayType={'text'} thousandSeparator={true} /></div>
                        <div className="my-auto">배팅중게임 : {userInfo.betCount}건</div>
                        <div>
                            <button className="btn btn-sm btn-outline-danger" onClick={onLogoutHandler}>LOGOUT</button>
                        </div>
                    </div>
                </div>
                :
                (
                    <div className="m-2 loginIdPw d-flex">
                        <form className="form-inline"
                              onSubmit={onSubmitHandler}>
                            <input type="text" maxLength="12" value={id} onChange={onIdHandler}
                                   placeholder="아이디"/>

                            <input  type="password" maxLength="12" value={pw} onChange={onPwHandler} onKeyDown={inputPress}
                                   placeholder="비밀번호"/>

                        </form>

                        <button onClick={onSubmitHandler} style={{
                            width: "80px"
                        }}>로그인
                        </button>
                        <button onClick={join} style={{
                            width: "80px",
                            background: "black",
                            border: "1px solid coral"
                        }}>회원가입
                        </button>
                        <button onClick={lost} style={{
                            width: "80px"
                        }}>분실문의
                        </button>
                    </div>
                )
            }
        </div>
    )
}

function join(e) {
    window.location.href = "/sign/codeCheck"
}
function  lost(e) {
    window.location.href = "/sign/lost"
}
export default LoginComponent;