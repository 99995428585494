import React, { useEffect, useState } from 'react';
import moment from "moment";
import axios from "axios";
import {Link} from "react-router-dom";
import {NumericFormat} from "react-number-format";

const CenterMenu = () => {
    const [eventList, setEventList] = useState([]);

    const [completeDeposit, setCompleteDeposit] = useState([]);
    const [completeWithdraw, setCompleteWithdraw] = useState([]);

    const getEventList = () => {
        axios.get('/user/event/get_event_list')
            .then((res) => {
                setEventList(res.data.eventList)
        })
    }
    //TODO 간헐적에러남.
    useEffect(getEventList, [])

    const completeList = () => {
        axios.get('/user/deposit/complete_list')
            .then((res) => {
                setCompleteDeposit(res.data.completeDeposit)
                setCompleteWithdraw(res.data.completeWithdraw)
       })
    }
    useEffect(completeList, [])

    function MarqueeList() {

        const [marqueeList, setMarqueeList] = useState([]);
        const getMarqueeList = () => {
            axios.get('/user/marquee/get_marquee_list')
                .then((res) => {
                setMarqueeList(res.data.marqueeList)
            })
        }
        //TODO 간헐적 에러남.
        useEffect(getMarqueeList, [])
        return(
            <div className="col-12 my-auto">
                <marquee behavior="scroll" scrolldelay="300" scrollamount="50" direction="left">
                    {marqueeList.map((marquee, index) => (
                      <span key={marquee.no} >
                            {marquee.title}
                      </span>
                    ))}
                </marquee>
            </div>
        );
    }

    function hideId(id) {
        let originId = id;
        let maskingId;
        let strLength = originId.length;

        if(strLength < 3){
            maskingId = originId.replace(/(?<=.1)./gi, "*");
        }else {
            maskingId = originId.replace(/(?<=.{3})./gi, "*");
        }
        return maskingId;
    }

    return (
        <div className="homeMain" >
            <div className="headline d-flex" style={{
                height: "30px"
            }}>
                <MarqueeList/>
            </div>

            <div className="home_main_slider">

            </div>
            <div className="centerMenu">
                <div className="quickMenu">
                    <Link to="/menu2/sports">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/national_sports.png"} alt="sports"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/sports">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/european_sports.png"} alt="sports"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/inPlay">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/live_sports.png"} alt="live_sports"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/miniGame">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/mini_game.png"} alt="mini_game"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/virtualGame">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/virtual_game.png"} alt="virtual_game"
                             style={{width: "100%"}}/>
                    </Link>
                </div>
            </div>

            <div className="centerMenu">
                <div className="quickMenu">
                    <Link to="/menu2/liveCasino">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/evolution.png"} alt="evolution_casino"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/liveCasino">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/plagmatic.png"} alt="plagmatic_sports"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/liveCasino">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/live_casino.png"} alt="live_casino"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/slotGame">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/live_slot.png"} alt="live_slot"
                             style={{width: "100%"}}/>
                    </Link>
                </div>

                <div className="quickMenu">
                    <Link to="/menu2/lotusGame">
                        <img src= {process.env.PUBLIC_URL + "/test/quickMenu/token&lotus_gaming.png"} alt="token_lotus_game"
                             style={{width: "100%"}}/>
                    </Link>
                </div>
            </div>

            <div className="centerMenu2">
                <div className="col-6 px-1">
                    <div className="deposit_withdrawal">
                        <div className="realTImeTitle">
                            <span>실시간입출금</span>
                        </div>

                        <div className="realTImeCover">
                            <div className="realTImeContent">
                                <div className="mt-2">
                                    {completeDeposit.map((completeDeposit, index) => (
                                        <div className="d-flex mt-2 justify-content-between" key={completeDeposit.no}>
                                            <div style={{width: "35%"}}>{moment(completeDeposit.updatedAt).format("YYYY-MM-DD HH:mm")}</div>
                                            <div style={{width: "15%"}}>입금</div>
                                            <div style={{width: "20%"}}>{hideId(completeDeposit.user.id)}</div>
                                            <div style={{width: "30%"}}>
                                                <span style={{float: "right", paddingRight: "3%", color: "gold"}}>
                                                    <NumericFormat value={completeDeposit.deposit_money} displayType={'text'} thousandSeparator={true} prefix={'￦'} />
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    {completeWithdraw.map((completeWithdraw, index) => (
                                        <div className="d-flex mt-2 justify-content-between" key={completeWithdraw.no}>
                                            <div style={{width: "35%"}}>{moment(completeWithdraw.updatedAt).format("YYYY-MM-DD HH:mm")}</div>
                                            <div style={{width: "15%"}}>출금</div>
                                            <div style={{width: "20%"}}>{hideId(completeWithdraw.user.id)}</div>
                                            <div style={{width: "30%"}}>
                                                <span style={{float: "right", paddingRight: "3%", color: "gold"}}>
                                                    <NumericFormat value={completeWithdraw.withdraw_money} displayType={'text'} thousandSeparator={true} prefix={'￦'} />
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-6 px-1">
                    <div className="eventList">
                        <div className="eventCenterMenu">
                            <span>이벤트</span>
                        </div>
                        <div className="eventCenterMenuContent">
                            <div>
                                {eventList.map((event, index) => (
                                    <div key={event.no} className="ms-3 mt-2">
                                        <div className="eventContent">
                                            <Link to="./menu1/event">
                                                {event.title}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottomMenu">
                <div className="d-flex justify-content-around pt-2 ps-2">
                    <a href="https://www.bet365.com/" target="_blank" className="my-auto" >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/bet365.png"} style={{width: "80%"}} alt="bet365" />
                    </a>
                    <a href="https://sports.bwin.com/en/sports" target="_blank" className="my-auto" >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/bwin.png"} style={{width: "80%"}} alt="bwin"/>
                    </a>
                    <a href="https://www.pinnacle.com/ko/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/pinnacle.png"} style={{width: "80%"}} alt="pinnacle"/>
                    </a>
                    <a href="https://dhlottery.co.kr/common.do?method=main" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/donghangLottery.png"} style={{width: "80%"}} alt="동행복권"/>
                    </a>
                    <a href="http://ntry.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/ntry.png"} style={{width: "80%"}} alt="ntry"/>
                    </a>
                    <a href="https://www.evolution.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/evolutionGaming.png"} style={{width: "80%"}} alt="evolution"/>
                    </a>
                    <a href="https://www.asia-gaming.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/asiagaming.png"} style={{width: "80%"}} alt="asia_gaming"/>
                    </a>
                </div>

                <div className="d-flex justify-content-around pt-1">
                    <a href="https://www.koreabaseball.com/" target="_blank" className="my-auto" >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/kbo.png"} style={{width: "80%"}} alt="korea_baseball"/>
                    </a>
                    <a href="https://www.kleague.com/" target="_blank" className="my-auto" >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/kleague.png"} style={{width: "80%"}} alt="k_league"/>
                    </a>
                    <a href="/" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/taishan.png"} style={{width: "80%"}} alt="taishan"/>
                    </a>
                    <a href="/" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/microgaming.png"} style={{width: "80%"}} alt="microgaming"/>
                    </a>
                    <a href="/" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/sexygaming.png"} style={{width: "80%"}} alt="sexygaming"/>
                    </a>
                    <a href="https://dreamgame.asia/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/dreamgame.png"} style={{width: "80%"}} alt="dreamgame"/>
                    </a>
                    <a href="https://www.development.orientalgame.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/orientalgame.png"} style={{width: "80%"}} alt="orientalgame"/>
                    </a>
                </div>

                <div className="d-flex justify-content-around pt-1 pb-4">
                    <a href="https://pragmaticplay.world/" target="_blank" className="my-auto" >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/pragmaticplay.png"} style={{width: "80%"}} alt="pragmatic_play"/>
                    </a>
                    <a href="https://rtgslots.com/" target="_blank" className="my-auto" >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/rtgslots.png"} style={{width: "80%"}} alt="rtg_slots"/>
                    </a>
                    <a href="https://www.fifa.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/fifa.png"} style={{width: "80%"}} alt="fifa"/>
                    </a>
                    <a href="https://www.nhl.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/nhl.png"} style={{width: "80%"}} alt="nhl"/>
                    </a>
                    <a href="https://www.mlb.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/mlb.png"} style={{width: "80%"}} alt="mlb"/>
                    </a>
                    <a href="https://www.nba.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/nba.png"} style={{width: "80%"}} alt="nba"/>
                    </a>
                    <a href="https://www.premierleague.com/" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/premierleague.png"} style={{width: "80%"}} alt="premier_league"/>
                    </a>
                    <a href="https://www.bundesliga.com/en/bundesliga" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/bundesliga.png"} style={{width: "80%"}} alt="bundesliga"/>
                    </a>
                    <a href="https://www.legaseriea.it/en" target="_blank" className="my-auto " >
                        <img src= {process.env.PUBLIC_URL + "/test/bottomMenu/legaseriea.png"} style={{width: "80%"}} alt="lega_seriea"/>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default CenterMenu;