import axios from "axios";
import {Link, useLocation, Outlet, Route, Routes} from "react-router-dom";
import Menu1 from "./pages/menu1";
import { NumericFormat } from 'react-number-format';
import React, {useEffect,useState} from "react";
import 'moment/locale/ko'
import Sign from "./pages/sign";
import Join from "./pages/sign/join";
import Login from "./pages/sign/login";
import CodeCheck from "./pages/sign/codeCheck";
import Lost from "./pages/sign/lost";

import Main from "./main/";
import LeftMenu from "./main/leftMenu";
import CenterMenu from "./main/centerMenu";
import RightMenu from "./main/rightMenu";

import Event from "./pages/menu1/event";
import Attend from "./pages/menu1/attend";
import Memo from "./pages/menu1/memo";
import Notice from "./pages/menu1/notice";
import Faq from "./pages/menu1/faq";
import Menu2 from "./pages/menu2";
import Deposit from "./pages/menu3/deposit";
import Withdraw from "./pages/menu3/withdraw";

import LoginComponent from "./components/loginComponent";

import BettingList from "./pages/menu1/bettinglist";
import GameResult from "./pages/menu1/gameResult";
import LiveCasino from "./pages/menu2/livecasino";
import SlotGame from "./pages/menu2/slotgame";
import InPlay from "./pages/menu2/inplay";
import Sports from "./pages/menu2/sports";
import KSports from "./pages/menu2/ksports";
import MiniGame from "./pages/menu2/minigame";
import VirtualGame from "./pages/menu2/virtualgame";
import LotusGame from "./pages/menu2/lotusgame";
import TokenGame from "./pages/menu2/tokengame";
import ServiceCenter from "./pages/menu3/servicecenter";
import {useDispatch, useSelector} from "react-redux";
import {updateUserInfo} from "./redux/reducers/userInfo";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="menu1" element={<Menu1 />}>
                        <Route path="event" element={<Event />}/>
                        <Route path="attend" element={<Attend />}/>
                        <Route path="memo" element={<Memo />}/>
                        <Route path="notice" element={<Notice />}/>
                        <Route path="faq" element={<Faq />}/>
                        {/*<Route path="betRule" element={<BetRule />}/>*/}
                        <Route path="bettingList" element={<BettingList />}/>
                        <Route path="gameResult" element={<GameResult />}/>
                    </Route>

                    <Route path="menu2" element={<Menu2 />}>
                        <Route path="liveCasino" element={<LiveCasino />}/>
                        <Route path="slotGame" element={<SlotGame />}/>
                        <Route path="inPlay" element={<InPlay />}/>
                        <Route path="sports" element={<Sports />}/>
                        {/*<Route path="kSports" element={<KSports />}/>*/}
                        <Route path="miniGame" element={<MiniGame />}/>
                        <Route path="virtualGame" element={<VirtualGame />}/>
                        <Route path="lotusGame" element={<LotusGame />}/>
                        <Route path="tokenGame" element={<TokenGame />}/>
                    </Route>

                    <Route path="menu3" element={<Menu2 />}>
                        <Route path="deposit" element={<Deposit />}/>
                        <Route path="withdraw" element={<Withdraw />}/>
                        <Route path="serviceCenter" element={<ServiceCenter />}/>
                    </Route>

                    <Route path="main" element={<Main />}>
                        <Route path="leftMenu" element={<LeftMenu />}/>
                        <Route path="centerMenu" element={<CenterMenu />}/>
                        <Route path="rightMenu" element={<RightMenu />}/>
                    </Route>

                    <Route path="sign" element={<Sign />}>
                        <Route path="codeCheck" element={<CodeCheck />}/>
                        <Route path="join" element={<Join />}/>
                        <Route path="lost" element={<Lost />}/>
                        <Route path="login" element={<Login />}/>
                    </Route>
                </Route>

            </Routes>
        </div>
    );
}

function Layout() {
    const location = useLocation();
    const home = location.pathname;
    const [ip, setIp] = useState([]);
    const [block, setBlock] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch()
    const getDevice = () => {
        axios.get('https://api.ip.pe.kr/json/')
        .then((res) => {
            setIp(res.data.ip)
        })
    }
    useEffect(getDevice, [])
    const getIpBlockList = () => {
        if( block !== null ) {
            axios.get('/user/ip_block/get_ip_block_list')
            .then((res) => {
                setBlock(res.data.ipBlockList)
            })
        }
    }
    useEffect(getIpBlockList, [])
    const result = {
        block: 0
    }
    for (let i = 0; i < block.length; i++) {
        if (ip === block[i].ip) {
            result.block += 1
        }
    }
    if (result.block > 0) {
        alert("접속불가")
        window.location.href ="http://www.google.com"
    }

    const userInfoReset = () => {
        axios.post("/user/member/get_user_info", {
            userNo: userInfo.userNo
        }).then((res) => {
            dispatch(updateUserInfo(res.data))
        })
    }
    useEffect(userInfoReset, [])

    return (
        <div className="ee1">
            <div className="header">
                <div>
                    <LoginComponent/>
                </div>
                <div className="col-12 align-items-center text-center d-flex">
                    <div className="col-5 menu2">
                        <div className="menu2_option">
                            <Link className="menu2Link" to="/menu2/inPlay">인플레이</Link>
                            <Link className="menu2Link" to="/menu2/sports">스포츠</Link>
                            <Link className="menu2Link" to="/menu2/liveCasino">라이브카지노</Link>
                            <Link className="menu2Link" to="/menu2/slotGame">슬롯게임</Link>
                            <Link className="menu2Link" to="/menu2/miniGame">미니게임</Link>
                            <Link className="menu2Link" to="/menu2/virtualGame">가상게임</Link>
                            <Link className="menu2Link" to="/menu2/lotusGame">로투스 / 토큰게임</Link>
                            {/*<Link className="menu2Link ms-3" to="/menu2/tokenGame">토큰게임</Link>*/}
                        </div>
                    </div>

                    <div className="col-2 logo">
                        <Link to="/">
                            <img src= {process.env.PUBLIC_URL + "/test/logo3.png"} />
                            {/*<img src= {process.env.PUBLIC_URL + "/test/topMenu/top_logo.gif"} />*/}
                        </Link>
                    </div>

                    <div className="col-5 menu1">
                        <div className="menu1_option">
                            <Link className="menu1Link" to="/menu1/event">이벤트</Link>
                            <Link className="menu1Link" to="/menu1/attend">출석부</Link>
                            <Link className="menu1Link" to="/menu1/memo">쪽지</Link>
                            <Link className="menu1Link" to="/menu1/notice">공지사항</Link>
                            <Link className="menu1Link" to="/menu1/faq">자주하는질문</Link>
                            {/*<Link className="menu1Link" to="/menu1/betRule">배팅규정</Link>*/}
                            <Link className="menu1Link" to="/menu1/bettingList">배팅내역</Link>
                            <Link className="menu1Link" to="/menu1/gameResult">경기결과</Link>
                            <Link className="menu1Link" to="/menu3/deposit">충전</Link>
                            <Link className="menu1Link" to="/menu3/withdraw">환전</Link>
                            <Link className="menu1Link" to="/menu3/serviceCenter">고객센터</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex" style={{
                background: "black",
                }}>
                <div className="col-2">
                    <LeftMenu/>
                </div>

                <div className="col-8 ">
                    {
                        home === "/" ?
                            <div>
                                <CenterMenu/>
                            </div>
                            :
                            (
                            <Outlet/>
                            )
                    }
                </div>

                <div className="col-2">
                    <RightMenu/>
                </div>
            </div>
        </div>
    );
}

export default App;
