import React, {useState} from 'react';
import axios from "axios";
import {NumericFormat} from "react-number-format";
import {clearCart, completedBet, removeCart} from "../redux/reducers/betCart";
import {useDispatch, useSelector} from "react-redux";
import {updateUserInfo} from "../redux/reducers/userInfo";

const RightMenu = () => {
    const betCart = useSelector((state) => state.betCart)
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch()
    const [money, setMoney] = useState(0);

    const onClickBet = () => {
        if(betCart.selectedList.length === 0) {
            alert("경기를 선택해주세요")
        }
        else if(money === 0){
            alert("배팅금을 입력해주세요")
        }
        else if(userInfo.user.money < money){
            alert("보유머니가 부족합니다")
        }
        else {
            axios.get('https://api.ip.pe.kr/json/')
                .then((res) => {
                    axios.post("/user/bet/insert", {
                        ratios: betCart.selectedList,
                        money: money,
                        userNo: userInfo.userNo,
                        ip: res.data.ip
                    }).then((response) => {
                        dispatch(completedBet())
                        axios.post("/user/member/get_user_info", {
                            userNo: userInfo.userNo
                        }).then((res2) => {
                            dispatch(updateUserInfo(res2.data))
                            setMoney(0)
                        })
                    })
                })
        }
    }

    const onMoneyHandler = (e) => {
        setMoney(e.currentTarget.value)
    }

    const onClickSetMoney = (m) => {
        setMoney((money + m))
    }
    const onClickResetMoney = (m) => {
        setMoney(m)
    }

    const onClickMax = () => {
        if(!userInfo.isLogin) {
            alert("로그인후 이용해주세요")
        }
        else {
            setMoney(userInfo.user.money)
        }

    }
    const onClickClearCart = () => {
        dispatch(clearCart())
        setMoney(0)
    }
    const onClickRemoveCart = (ratio, betNo) => {
        const _ratio = Object.assign({}, ratio)
        _ratio.bet_no = betNo
        dispatch(removeCart({ratio:_ratio}))
    }
    function getBet(ratio) {
        //선택한팀
        let team
        // let teamName = ratio.home + "vs" + ratio.away
        //마켓종류
        let baseLine = ""
        if(ratio.market_id === 2 || ratio.market_id === 3) {
            baseLine = " "+ratio.market_baseline.replace(" (0-0)", "")
        }
        //배당
        let r
        if(ratio.bet_no === 1) {
            team = ratio.home
            r = ratio.bet1
        }
        else if(ratio.bet_no === 2) {
            team = "무"
            r = ratio.bet2
        }
        else if(ratio.bet_no === 3) {
            team = ratio.away
            r = ratio.bet3
        }

        return (
            <>
                <div>{team}</div>
                <div>{r}</div>
            </>
        )
    }

    function gameType(market) {
        switch (market) {
            case "1X2" : return "승무패"
            case "Asian Handicap" : return "핸디캡"
            case "Under/Over" : return "오버언더"
        }
    }
    return(
        <div className="rightMenu">
            {userInfo.isLogin?
                <div>
                    <div className="betSlip">
                        <div className="betSlip_title">
                            <h6>Bet Slip</h6>
                        </div>
                        <div className="betSlip_content">
                            <div className="d-flex justify-content-between">
                                <span className="ms-1 my-auto" style={{fontSize: "12px"}}>배팅금액</span>
                                <NumericFormat value={money} thousandSeparator="," onChange={onMoneyHandler} prefix={'￦'}
                                               style={{
                                                   width: "50%",
                                                   color: "white",
                                                   background: "black",
                                                   border: "1px solid black",
                                                   textAlign: "right",
                                                   paddingRight: "2%"
                                               }}/>
                                <button className="ms-1 betSlipReset" onClick={() => {onClickResetMoney(0)}}>Reset</button>
                            </div>

                            <div>
                                <div className="mt-3 d-flex justify-content-between">
                                    <button className="btn btn-outline-dark ms-1 betSlipButton" type="button" onClick={() => {onClickSetMoney(5000)}}>5,000</button>
                                    <button className="btn btn-outline-dark ms-1 betSlipButton" type="button" onClick={() => {onClickSetMoney(10000)}}>10,000</button>
                                    <button className="btn btn-outline-dark ms-1 betSlipButton" type="button" onClick={() => {onClickSetMoney(50000)}}>50,000</button>
                                </div>
                                <div className="mt-1 d-flex justify-content-between">
                                    <button className="btn btn-outline-dark ms-1 betSlipButton" type="button" onClick={() => {onClickSetMoney(100000)}}>100,000</button>
                                    <button className="btn btn-outline-dark ms-1 betSlipButton" type="button" onClick={() => {onClickSetMoney(500000)}}>500,000</button>
                                    <button className="btn btn-outline-dark ms-1 betSlipButton" type="button" onClick={() => {onClickMax()}}>MAX</button>
                                </div>
                                <div className="mt-1">
                                    {betCart.selectedList.map((ratio, index) => (
                                        <div className="mt-3 selectedList">
                                            <div className="mt-1 d-flex justify-content-between selectRatio">
                                                {getBet(ratio)}
                                            </div>
                                            <div className="mt-1">
                                                {gameType(ratio.market)}
                                            </div>
                                            <div className="mt-1 d-flex justify-content-between">
                                                <div>
                                                    {ratio.home} <span className="mx-1" style={{color: "coral"}}>VS</span> {ratio.away}
                                                </div>
                                                <div className="my-auto">
                                                    <button className="remove" onClick={() => onClickRemoveCart(ratio, ratio.bet_no)}>X</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="mt-2">
                                <div className="levelBetMaximum">
                                    <div className="d-flex">
                                        <div className="mt-2 col-6">
                                            최대 배팅금
                                        </div>
                                        <div className="mt-2 col-6 text-end">
                                            <NumericFormat value="2000000" displayType={'text'} thousandSeparator={true} style={{color: "yellow"}}/> 원
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <div className="mt-1 col-6">
                                            최대 당첨금
                                        </div>
                                        <div className="mt-1 col-6 text-end">
                                            <NumericFormat value="5000000" displayType={'text'} thousandSeparator={true} style={{color: "yellow"}}/> 원
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2"></div>


                                <div className="bet_odd">
                                    <div className="d-flex">
                                        <div className="mt-2 col-6">
                                            총배당
                                        </div>
                                        <div className="mt-2 col-6 text-end">
                                            <NumericFormat value={Math.floor(betCart.totalRatio * 100) / 100} displayType={'text'} thousandSeparator={true} style={{color: "yellow"}}/> 배
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <div className="mt-1 col-6">
                                            총배당 금액
                                        </div>
                                        <div className="mt-1 col-6 text-end">
                                            <NumericFormat value={Math.floor(betCart.totalRatio * money)} displayType={'text'} thousandSeparator={true} style={{color: "yellow"}}/> 원
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="betting mt-2">
                                <button className="bettingButton" onClick={onClickBet}>배팅하기</button>

                            </div>

                            <div className="betting mt-2">
                                <button className="bettingClear" onClick={onClickClearCart}>전체취소</button>

                            </div>

                        </div>


                    </div>
                    <div>
                        <div className="rightMenu2">
                            <img src= {process.env.PUBLIC_URL + "/test/rightMenu/bn_telegram.gif"} alt="banner_telegram"
                                 style={{width: "100%"}}/>
                        </div>
                        <div className="rightMenu2">
                            <img src= {process.env.PUBLIC_URL + "/test/rightMenu/bn_domain.gif"} alt="banner_domain"
                                 style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>

                :(
                    <div>
                        <h6 className="rightMenu1">인기슬롯게임</h6>
                        <div className="rightMenu2">
                            <img src= {process.env.PUBLIC_URL + "/test/rightMenu/slot1.png"} alt="slot"
                                 style={{width: "100%"}}/>
                        </div>
                        <div className="rightMenu2">
                            <img src= {process.env.PUBLIC_URL + "/test/rightMenu/slot2.png"} alt="slot"
                                 style={{width: "100%"}}/>
                        </div>

                        <div className="rightMenu2">
                            <img src= {process.env.PUBLIC_URL + "/test/rightMenu/bn_telegram.gif"} alt="banner_telegram"
                                 style={{width: "100%"}}/>
                        </div>
                        <div className="rightMenu2">
                            <img src= {process.env.PUBLIC_URL + "/test/rightMenu/bn_domain.gif"} alt="banner_domain"
                                 style={{width: "100%"}}/>
                        </div>
                    </div>
                )}
        </div>
    );
}
export default RightMenu;