import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const VirtualGame = () => {
    return (
        <div className="main">
            <div className="subtitle">
                가상게임 VIRTUAL GAME
            </div>
        </div>
    )
}
export default VirtualGame;