import React, {useEffect} from 'react';
import GameResultComponent from "../../components/menu1/gameResultComponent";
import {useDispatch, useSelector} from "react-redux";
import {getGameResultReducer} from "../../redux/reducers/menu1/gameResultReducer";
import Pagination from "../../components/common/Pagination";
import {getBettingListReducer} from "../../redux/reducers/menu1/bettingListReducer";
const GameResultContainer = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo);
    const initialState = useSelector((state) => state.gameResultReducer)
    const getGameResultList = () => {
        if(initialState.gameResultList !== null) {
            dispatch(getGameResultReducer({
                page:initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                userNo: userInfo.userNo,
            }))
        }
    }

    const handlePageChange = (page) => {
        dispatch(getGameResultReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            userNo: userInfo.userNo,
        }))
    }
    useEffect(getGameResultList, [dispatch])

    return (
        <div className="main">
            <div className="subtitle">
                경기결과 GAME RESULT
            </div>

            <div className="mt-2 gameResult">
                <div className="justify-content-center gameType btn-group" style={{display: "flex"}}>
                    <button type = "button" className="btn btn-danger">스포츠</button>
                    <button className="btn btn-danger">미니게임</button>
                    <button className="btn btn-danger">가상게임</button>
                    <button className="btn btn-danger">로투스게임</button>
                </div>
                <div className="justify-content-center sportType btn-group" style={{display: "flex"}}>
                    <button className="btn btn-danger">전체</button>
                    <button type = "button" className="btn btn-danger">축구</button>
                    <button type = "button" className="btn btn-danger">농구</button>
                    <button type = "button" className="btn btn-danger">야구</button>
                    <button type = "button" className="btn btn-danger">배구</button>
                    <button type = "button" className="btn btn-danger">하키</button>
                    <button type = "button" className="btn btn-danger">테니스</button>
                    <button type = "button" className="btn btn-danger">기타</button>
                </div>
                <div className="mt-1">
                    <div className="col-16 text-end my-auto">
                        <div className="d-flex align-items-center justify-content-end mb-2"> {/* 열을 나누고 select를 추가하는 부분 */}
                            <select
                                className="form-select bg-dark text-light"
                                style={{
                                    width: "100px",
                                    height: "33px", // 세로 크기 조정
                                    borderColor: "#008080",
                                    fontSize: "13px" // 글자 크기 조정
                                }}
                                value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                onChange={(e) => {
                                    dispatch(getGameResultReducer({
                                        page: 1,
                                        pageSize: parseInt(e.target.value),
                                        order: initialState.order,
                                        userNo: userInfo.userNo,
                                    }))
                                }}
                            >
                                <option value={20}>20개씩</option>
                                <option value={50}>50개씩</option>
                                <option value={100}>100개씩</option>
                                <option value={200}>200개씩</option>
                                <option value={999}>모두</option>
                            </select>
                            <select
                                className="form-select bg-dark text-light"
                                style={{
                                    width: "135px",
                                    height: "33px", // 세로 크기 조정
                                    borderColor: "#008080",
                                    fontSize: "13px" // 글자 크기 조정
                                }}
                                value={initialState.order === 0? 0 : initialState.order}
                                onChange={e =>
                                    dispatch(getGameResultReducer({
                                        page: 1,
                                        pageSize: initialState.pageSize,
                                        order: parseInt(e.target.value),
                                        userNo: userInfo.userNo,
                                    }))
                                }
                            >
                                <option value={0}>경기시간순</option>
                                <option value={1}>베팅접수시간순</option>
                            </select>
                        </div>
                    </div>
                </div>
                <GameResultComponent initialState={initialState}/>
                <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    )
}
export default GameResultContainer;