import React, {useEffect, useState} from 'react';
import {Link, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import LoginModal from "../../components/LoginModal";

const Menu1 = () => {
    const userInfo = useSelector((state) => state.userInfo);
    return (
        <div style={{
                width: "100%",
            }}>
            {userInfo.isLogin?
            <Outlet />
                :(
                    <div>
                        <LoginModal/>
                    </div>
                )}
        </div>
    )
}

export default Menu1;