import {createSlice} from "@reduxjs/toolkit"

export const bettingListReducerSlice = createSlice({
    name: 'bettingListReducer',
    initialState: {
        page:1,
        pageSize:20,
        order:0,
        isLoading: false,
        error: null,
        totalCount : 0,
        userBetCount : 0,
        userBets: []
    },
    reducers: {
        getBettingListReducer(state, action) {
            console.log("#reducers getBettingListReducer start#");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.userNo = action.payload.userNo;
            state.order = action.payload.order;
        },
        setBettingListReducer(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            console.log("#reducers setBettingListReducer start#",action.payload.result,((state.page -1) * state.pageSize > action.payload.totalCount),state.page,state.pageSize,action.payload.totalCount);

            state.userBets = action.payload.result;
            state.userBetCount  = action.payload.userBetCount;
            state.totalCount  = action.payload.totalCount;

        },
        failedGetBettingListReducer(state, action) {
            console.log("#reducers failedGetBettingListReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const {
    getBettingListReducer,
    setBettingListReducer,
    failedGetBettingListReducer,
} = bettingListReducerSlice.actions;

export default bettingListReducerSlice.reducer;