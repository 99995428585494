import {createSlice} from "@reduxjs/toolkit"
import {PURGE} from "redux-persist";

const initialState = {
    isLoading: false,
    userNo: -1,
    user: null,
    betCount: 0,
    isLogin: false,
    error: null
}

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        login(state, action) {

            state.id = action.payload.id;
        },
        setUserInfo(state, action) {
            if(state.isLogin) {
                state.user = action.payload.user;
            }
            else {
                if(action.payload.isLogin) {
                    state.isLogin = action.payload.isLogin;
                    state.userNo = action.payload.user.no;
                    state.user = action.payload.user;
                    state.betCount = action.payload.betCount;
                }
                else {
                    state.isLogin = action.payload.isLogin;
                }
            }
        },
        fetchUserInfo(state, action) {
          state.userNo = action.payload.userNo
        },
        updateUserInfo(state, action) {
            state.userNo = action.payload.user.no;
            state.user = action.payload.user;
            state.betCount = action.payload.betCount;
        },
        failedLogin(state, action) {
            state.isLoading = false;
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
})

export const { login, fetchUserInfo, setUserInfo, updateUserInfo, failedLogin } = userInfoSlice.actions
export default userInfoSlice.reducer