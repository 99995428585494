import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const SlotGame = () => {
    return (
        <div className="main">
            <div className="subtitle">
                슬롯게임 SLOT GAME
            </div>
        </div>
    )
}
export default SlotGame;