import React, {useEffect} from 'react';
import moment from "moment";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { NumericFormat } from 'react-number-format';
import {updateUserInfo} from "../../redux/reducers/userInfo";
import {getDepositReducer,getDepositCheckBankPwReducer,setPw,setDepositMoney} from "../../redux/reducers/menu3/depositReducer";
import Pagination from "../../components/common/Pagination";

const DepositComponent = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    const initialState = useSelector((state) => state.depositReducer)
    const getUserBasicInfo = () => {
        if(initialState.name !== null) {
            dispatch(getDepositReducer({
                page:initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                userNo: userInfo.userNo,
            }))
        }
    }
    useEffect(getUserBasicInfo, [dispatch])
    const onDepositMoneyHandler = (e) => {
        console.log("##그냉입력##"+e.currentTarget.value)
        const inputValue = e.currentTarget.value;
        const numericValue = inputValue.replace(/[^\d]/g, '');
        dispatch(setDepositMoney(parseInt(numericValue)))
    }

    const handlePageChange = (page) => {
        dispatch(getDepositReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            userNo: userInfo.userNo,
        }))
    }

    const onClickSetDepositMoney = (m) => {
        console.log("##이니셜라이##"+initialState.depositMoney);
        console.log("##누른값##"+m);
        dispatch(setDepositMoney((initialState.depositMoney + m)))
    }
    const onClickResetMoney = (m) => {
        dispatch(setDepositMoney(m))
    }

    const onPwHandler = (e) => {
        const currentPw = e.currentTarget.value
        dispatch(setPw(currentPw))
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentPw)) {
            alert("영어,숫자조합만 가능합니다")
            dispatch(setPw(""))
        }
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            onPwButtonHandler(e.target.value)
        }
    }

    const onPwButtonHandler = (e) => {
        dispatch(getDepositCheckBankPwReducer({
            no: userInfo.userNo,
            name: initialState.name,
            bankPw: initialState.pw
        }))
    }
    const onDepositButtonHandler = (e) => {
        if(initialState.isConfirm) {
            axios.post('/user/deposit/insert', {
                userNo: userInfo.userNo,
                requestMoney: initialState.depositMoney,
                accountNo: initialState.account_no
            }).then((res) => {
                getUserBasicInfo()
                axios.post("/user/member/get_user_info", {
                    userNo: userInfo.userNo
                }).then((res2) => {
                    dispatch(updateUserInfo(res2.data))
                })
            })
        }
        else {
            alert("입금계좌정보를 먼저 확인해주세요.")
        }
    }
    function getDepositType(type) {
        switch (type) {
            case 0 : return "스포츠"
            case 1 : return "카지노"
            default : return null
        }
    }
    function getDepositState(state) {
        switch (state) {
            case 0 : return "충전신청"
            case 1 : return "충전대기"
            case 2 : return "충전완료"
            case 3 : return "충전취소"
            default : return null
        }
    }

    return (
        <div className="main">
            <div className="subtitle">
                입금 DEPOSIT
            </div>
            <div className="mt-2">
                <table className="depositt1">
                    <thead>
                    <tr>
                        <th colSpan="2"> 입금(충전신청)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>보유머니</td>
                        <td className="td2">
                            <NumericFormat value={initialState.money} displayType={'text'} thousandSeparator={true} prefix={'￦'} />
                        </td>
                    </tr>
                    <tr>
                        <td>예금주</td>
                        <td className="td2">{initialState.name}</td>
                    </tr>

                    <tr>
                        <td>입금계좌정보</td>
                        <td className="d-flex">
                            {initialState.isConfirm? (<div className="td2">{initialState.account_bank + " " + initialState.account_accountNum + " " + initialState.account_owner}</div>)
                                : (<>
                                    <div className="w-50">
                                        <input type="text" maxLength="12" className="form-control" value={initialState.pw} placeholder="출금 비밀번호를 입력하여 주십시오."
                                               aria-describedby="button-addon2" onChange={onPwHandler} onKeyDown={inputPress} />
                                    </div>
                                    <button className="btn btn-outline-secondary ms-2 button1" type="button"
                                            id="button-addon2" onClick={onPwButtonHandler}>확인
                                    </button>
                                </>)}
                        </td>
                    </tr>
                    <tr>
                        <td>입금신청금액</td>
                        <td className="d-flex">
                            <div className="w-50">
                                <NumericFormat className="w-100 mt-1" maxLength="11" value={initialState.depositMoney}
                                               thousandSeparator="," onChange={onDepositMoneyHandler} prefix={'￦'} />
                            </div>
                            <button className="btn btn-outline-secondary ms-2 button1" type="button"
                                    id="button-addon2" onClick={onDepositButtonHandler}>입금신청
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <button className="btn btn-outline-success ms-2 button2" type="button"
                                    onClick={()=> {
                                        onClickSetDepositMoney(10000)
                                    }}>10,000</button>
                            <button className="btn btn-outline-success ms-2 button2" type="button"
                                    onClick={()=> {
                                        onClickSetDepositMoney(30000)
                                    }}>30,000</button>
                            <button className="btn btn-outline-success ms-2 button2" type="button"
                                    onClick={()=> {
                                        onClickSetDepositMoney(50000)
                                    }}>50,000</button>
                            <button className="btn btn-outline-success ms-2 button2" type="button"
                                    onClick={()=> {
                                        onClickSetDepositMoney(100000)
                                    }}>100,000</button>
                            <button className="btn btn-outline-success ms-2 button2" type="button"
                                    onClick={()=> {
                                        onClickSetDepositMoney(500000)
                                    }}>500,000</button>
                            <button className="btn btn-outline-danger ms-2 buttonClear" type="button"
                                    onClick={()=> {
                                        onClickResetMoney(0)
                                    }}>CLEAR</button>
                        </td>
                    </tr>
                    </tbody>


                </table>
            </div>
            <div className="mt-4">
                <div className="subtitle">
                    충전내역
                </div>
            </div>
            <div className="mt-1">
                <div className="col-16 text-end my-auto">
                    <div className="d-flex align-items-center justify-content-end mb-2"> {/* 열을 나누고 select를 추가하는 부분 */}
                        <select
                            className="form-select bg-dark text-light"
                            style={{
                                width: "100px",
                                height: "33px", // 세로 크기 조정
                                borderColor: "#008080",
                                fontSize: "13px" // 글자 크기 조정
                            }}
                            value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                            onChange={(e) => {
                                dispatch(getDepositReducer({
                                    page: 1,
                                    pageSize: parseInt(e.target.value),
                                    order: initialState.order,
                                    userNo: userInfo.userNo,
                                }))
                            }}
                        >
                            <option value={5}>5개씩</option>
                            <option value={10}>10개씩</option>
                            <option value={20}>20개씩</option>
                        </select>
                        <select
                            className="form-select bg-dark text-light ml-2"
                            style={{
                                width: "135px",
                                height: "33px", // 세로 크기 조정
                                borderColor: "#008080",
                                fontSize: "13px" // 글자 크기 조정
                            }}
                            value={initialState.order === 0? 0 : initialState.order}
                            onChange={e =>
                                dispatch(getDepositReducer({
                                    page: 1,
                                    pageSize: initialState.pageSize,
                                    order: parseInt(e.target.value),
                                    userNo: userInfo.userNo,
                                }))
                            }
                        >
                            <option value={0}>신청시간순</option>
                            <option value={1}>처리시간순</option>
                        </select>
                    </div>
                </div>
                <table className="depositt1">
                    <thead>
                    <tr>
                        <th>신청시간</th>
                        <th>신청금액</th>
                        <th>처리시간</th>
                        <th>구분</th>
                        <th>상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    {initialState.depositList&&initialState.depositList.map((deposit, index) => (
                        <tr key={deposit.no}>
                            <td>{moment(deposit.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            <td><NumericFormat value={deposit.request_money} displayType={'text'} thousandSeparator={true} prefix={'￦'} /></td>
                            <td>{moment(deposit.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{getDepositType(deposit.deposit_type)}</td>
                            <td>{getDepositState(deposit.deposit_state)}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-center mt-2"> {/* text-center 클래스 추가 */}
                <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
            </div>
        </div>

    )
}
export default DepositComponent;