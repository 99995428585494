import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";

const Memo = ({memo, onRefresh}) => {

    const [memoList, setMemoList] = useState([]);
    const [receive, setReceive] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);
    const getMemoList = () => {
        axios.post('/user/memo/get_memo_list', {
            userNo: userInfo.userNo
        }).then((res) => {
            setMemoList(res.data.memoList)
        })
    }
    useEffect(getMemoList, [])

    const receiveHandler = () => {
        axios.post('/user/memo/get_receive', {
            userNo: userInfo.userNo
        }).then((res) => {
            setReceive(res.data.receive)
        })
    }

    return (
        <div className="main">
            <div className="subtitle">
                쪽지 MEMO
            </div>
            <div className="mt-2">
                <table className="table table-bordered table-sm text-center align-middle noticet1">
                    <thead className="table-dark">
                    <tr>
                        <th>제목</th>
                        <th width="200px">보낸시간</th>
                    </tr>
                    </thead>
                    {memo.map((memo, index) => (
                    <tbody key={memo.no}>
                        <tr>
                            <td className="memoTitle">
                                <Link className="nav-link" data-bs-toggle="collapse" to={"#collapse" + index}
                                      aria-expanded="false" aria-controls={"collapse" + index}
                                      onClick={receiveHandler}
                                >
                                    {memo.title}
                                </Link>
                            </td>
                            <td className="memoTitle">{moment(memo.createdAt).format("YYYY-MM-DD HH:mm")}</td>

                        </tr>
                        <tr className="collapse" id={"collapse" + index}>
                            <td className="memoContent" colSpan="2">
                                <div dangerouslySetInnerHTML={ {__html:memo.cont}}></div>
                            </td>
                        </tr>
                    </tbody>
                    ))}

                </table>
            </div>

        </div>
    )
}
export default Memo;