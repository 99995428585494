import React from 'react';
import moment from "moment";
import {Link} from "react-router-dom";

const LeftMenu = () => {
    return (
        <div className="leftMenu">
            <div className="bn_event">
                <img src= {process.env.PUBLIC_URL + "/test/leftMenu/bn_event.gif"} alt="banner_telegram"
                     style={{width: "100%"}}/>
            </div>
            <div className="text-center my-auto currentTime">
                GMT+9 {moment().format("YYYY.MM.DD HH:mm:ss (dd)")}
            </div>
            <div className="searchLeague">
                <div className="mt-1 my-1 d-flex">
                    <input type="text" placeholder="리그찾기" />
                    <button>
                        <img src= {process.env.PUBLIC_URL + "/test/leftMenu/search_icon.png"} style={{
                            width: "20px"
                        }}/>
                    </button>
                </div>
            </div>
            <div className="gameTypeList">
                <div><img src= {process.env.PUBLIC_URL + "/test/leftMenu/coin.png"} />라이브카지노</div>
                <div><img src= {process.env.PUBLIC_URL + "/test/leftMenu/coin.png"} />미니게임</div>
                <div><img src= {process.env.PUBLIC_URL + "/test/leftMenu/coin.png"} />가상게임</div>
                <div><img src= {process.env.PUBLIC_URL + "/test/leftMenu/coin.png"} />로투스게임</div>
                <div><img src= {process.env.PUBLIC_URL + "/test/leftMenu/coin.png"} />토큰게임</div>
                <div><img src= {process.env.PUBLIC_URL + "/test/leftMenu/coin.png"} />슬롯게임</div>
            </div>
            <div className="mt-2 todayMatch">
                <div className="title">
                    <span>오늘의 경기 Today Match</span>
                </div>
                <div className="gameType">
                    <div className="d-flex justify-content-between">
                        <div style={{width: "60%"}}>
                            <Link data-bs-toggle="collapse" to="#collapse1" aria-expanded="false" aria-controls="collapse1" >
                                <img src= {process.env.PUBLIC_URL + "/test/leftMenu/soccer.png"} />축구
                            </Link>
                        </div>
                        <div>
                            <span>10</span>
                        </div>
                    </div>
                </div>
                <div className="collapse" id="collapse1">
                    <div className="d-flex justify-content-between m-1 pb-1">
                        <div style={{width: "60%"}}>
                            <Link data-bs-toggle="collapse" to="#collapse2" aria-expanded="false" aria-controls="collapse2" style={{
                                color: "white",
                                fontSize: "13px",
                            }}>
                                <img src= {process.env.PUBLIC_URL + "/test/leftMenu/southKorea.png"} style={{width: "20px", marginLeft: "5%", marginRight: "2%"}}/>대한민국
                            </Link>
                        </div>
                        <div>
                            <span style={{color: "coral", fontSize: "13px"}}>10</span>
                        </div>
                    </div>
                </div>
                <div className="collapse pb-1" id="collapse2">
                    <div className="border border-secondary">
                        <div className="d-flex justify-content-between m-1">
                            <div style={{width: "60%"}}>
                                <div style={{fontSize: "13px", marginLeft: "10%"}}>
                                    > k리그1
                                </div>
                            </div>
                            <div>
                                <span style={{color: "coral", fontSize: "13px"}}>10</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between m-1">
                            <div style={{width: "60%"}}>
                                <div style={{fontSize: "13px", marginLeft: "10%"}}>
                                    > k리그2
                                </div>
                            </div>
                            <div>
                                <span style={{color: "coral", fontSize: "13px"}}>10</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gameType">
                    <div className="d-flex justify-content-between">
                        <div style={{width: "60%"}}>
                            <img src= {process.env.PUBLIC_URL + "/test/leftMenu/soccer.png"} />농구
                        </div>
                        <div>
                            <span>10</span>
                        </div>
                    </div>
                </div>
                <div className="gameType">
                    <div className="d-flex justify-content-between">
                        <div style={{width: "60%"}}>
                            <img src= {process.env.PUBLIC_URL + "/test/leftMenu/soccer.png"} />야구
                        </div>
                        <div>
                            <span>10</span>
                        </div>
                    </div>
                </div>
                <div className="gameType">
                    <div className="d-flex justify-content-between">
                        <div style={{width: "60%"}}>
                            <img src= {process.env.PUBLIC_URL + "/test/leftMenu/soccer.png"} />배구
                        </div>
                        <div>
                            <span>10</span>
                        </div>
                    </div>
                </div>
                <div className="gameType">
                    <div className="d-flex justify-content-between">
                        <div style={{width: "60%"}}>
                            <img src= {process.env.PUBLIC_URL + "/test/leftMenu/soccer.png"} />하키
                        </div>
                        <div>
                            <span>10</span>
                        </div>
                    </div>
                </div>
                <div className="gameType">
                    <div className="d-flex justify-content-between">
                        <div style={{width: "60%"}}>
                            <img src= {process.env.PUBLIC_URL + "/test/leftMenu/soccer.png"} />기타
                        </div>
                        <div>
                            <span>10</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LeftMenu;