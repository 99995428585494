import {call, put} from "redux-saga/effects";
import {fetchWithdraw, fetchWithdrawCheckBankPw} from "../../../lib/api";
import {
    failedGetWithdrawCheckBankPwReducer,
    failedGetWithdrawReducer,
    setWithdrawCheckBankPwReducer,
    setWithdrawReducer
} from "../../reducers/menu3/withdrawReducer";

export function* getWithdrawSaga(action) {
    console.log("##getWithdrawSaga##");
    try{
        const res = yield call(fetchWithdraw,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.userNo);
        yield put(setWithdrawReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetWithdrawReducer(error))
    }
}

export function* getWithdrawCheckBankPwSaga(action) {
    console.log("##getWithdrawCheckBankPwSaga##",action.payload.no,action.payload.name,action.payload.bankPw);
    try{
        const res = yield call(fetchWithdrawCheckBankPw,action.payload.no,action.payload.name,action.payload.bankPw);
        yield put(setWithdrawCheckBankPwReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetWithdrawCheckBankPwReducer(error))
    }
}
