import {all, fork, takeEvery, takeLatest} from 'redux-saga/effects';
import {loginSaga, setUserInfoSaga} from "./userInfo";
import {betSaga} from "./betCart";
import {bet} from "../reducers/betCart";
import {fetchUserInfo} from "../reducers/userInfo";
import {getBettingListReducer} from "../reducers/menu1/bettingListReducer";
import {getBettingListSaga} from "./menu1/bettingListSaga";
import {getGameResultReducer} from "../reducers/menu1/gameResultReducer";
import {getGameResultSaga} from "./menu1/gameResultSaga";
import {getDepositReducer} from "../reducers/menu3/depositReducer";
import {getDepositSaga} from "./menu3/depositSaga"
import {getDepositCheckBankPwReducer} from "../reducers/menu3/depositReducer";
import {getDepositCheckBankPwSaga} from "./menu3/depositSaga"
import {getServiceCenterReducer} from "../reducers/menu3/serviceCenterReducer";
import {getServiceCenterSaga} from "./menu3/serviceCenterSaga"
import {getWithdrawReducer} from "../reducers/menu3/withdrawReducer";
import {getWithdrawSaga} from "./menu3/withdrawSaga"
import {getWithdrawCheckBankPwReducer} from "../reducers/menu3/withdrawReducer";
import {getWithdrawCheckBankPwSaga} from "./menu3/withdrawSaga"
import {getEvent} from "../reducers/menu1/event";
import {getEventSaga} from "./menu1/event";

import {getFaq} from "../reducers/menu1/faq";
import {getFaqSaga} from "./menu1/faq";

import {getNotice} from "../reducers/menu1/notice";
import {getNoticeSaga} from "./menu1/notice";

import {getMemo} from "../reducers/menu1/memo";
import {getMemoSaga} from "./menu1/memo";

import {getAttend} from "../reducers/menu1/attend";
import {getAttendSaga} from "./menu1/attend";

export function* watcherSaga() {
    yield all([
        fork(setUserInfoSaga),
    ])

    //user
    yield takeLatest(fetchUserInfo.type, fetchUserInfo)

    //bet
    yield takeLatest(bet.type, betSaga)

    yield takeLatest(getBettingListReducer.type, getBettingListSaga)
    yield takeLatest(getGameResultReducer.type, getGameResultSaga)
    yield takeLatest(getDepositReducer.type, getDepositSaga)
    yield takeLatest(getDepositCheckBankPwReducer.type, getDepositCheckBankPwSaga)
    yield takeLatest(getWithdrawReducer.type,getWithdrawSaga)
    yield takeLatest(getWithdrawCheckBankPwReducer.type,getWithdrawCheckBankPwSaga)
    yield takeLatest(getServiceCenterReducer.type,getServiceCenterSaga)
    //metch1-event
    yield takeLatest(getEvent.type, getEventSaga)

    //metch1-faq
    yield takeLatest(getFaq.type, getFaqSaga)

    //metch1-notice
    yield takeLatest(getNotice.type, getNoticeSaga)

    //metch1-memo
    yield takeLatest(getMemo.type, getMemoSaga)

    //metch1-attend
    yield takeLatest(getAttend.type, getAttendSaga)
}
