import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const LotusGame = () => {
    return (
        <div className="main">
            <div className="subtitle">
                로투스게임 LOTUS GAME
            </div>
        </div>
    )
}
export default LotusGame;