import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const eventSlice = createSlice({
    name: 'event',
    initialState: {
        isLoading: false,
        error: null,
        event: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
    },
    reducers: {
        getEvent(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setEvent(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.event = action.payload.result;
        },
        failedGetEvent(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getEvent, setEvent, failedGetEvent } = eventSlice.actions;

export default eventSlice.reducer;