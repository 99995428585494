import React, {useEffect, useState} from 'react';
import axios from "axios";

const CodeCheck = () => {

    const [code, setCode] = useState("");
    const [codeList, setCodeList] = useState([]);

    const getCodeList = () => {
        axios.post('/user/join_code/get_join_code')
        .then((res) => {
            setCodeList(res.data.codeList)
            console.log(res.data.codeList)
        })
    }
    useEffect(getCodeList, [])


    const onCodeHandler = (e) => {
        setCode(e.currentTarget.value);
    }
    const onCheckEnter = (e) => {
        if(e.key === 'Enter') {
            onSubmitHandler()
        }
    }
    const onSubmitHandler = (e) => {
        const result = {
            check: 0
        }
        for (let i = 0; i < codeList.length; i++) {
            if (code !== codeList[i].store_code) {
                result.check += 1
            }
        }
        if (result.check > 0) {
            alert('추천인 코드가 올바르지 않습니다.')
        }
        else {
            window.location.href = "/sign/join"
        }
    }

    return (
        <div className="main">
            <div className="subtitle">
                    회원가입 MEMBER JOIN
            </div>
            <div className="text-center">
                <div className="codeCheck">
                    <div className="triangle-right">
                        <div className="step">1 단계</div>
                    </div>
                    <div className="stepDetail my-auto">
                        <span style={{color: "gold"}}>가입코드 입력</span><br></br>가입코드를 입력해주세요
                    </div>

                    <div className="triangle-right-off">
                        <div className="step">2 단계</div>
                    </div>
                    <div className="stepDetail my-auto">
                        <span style={{color: "gray"}}>가입정보 입력</span><br></br>기입에 필요한 정보를 입력하세요
                    </div>

                    <div className="triangle-right-off">
                        <div className="step">3 단계</div>
                    </div>
                    <div className="stepDetail my-auto">
                        <span style={{color: "gray"}}>가입완료 및 이용안내</span><br></br>가입완료및 사이트 이용안내
                    </div>
                </div>
                <div className="mt-2 pb-4" style={{
                    background: "#434343",
                    }}>
                    <div style={{color: "white"}}>
                        <label className="mt-2" >가입 코드를 입력해주세요</label>
                    </div>
                    <div className="mt-3">
                        <input type="text" value={code} onChange={onCodeHandler} onKeyDown={onCheckEnter}
                               placeholder="가입 코드"
                            style={{
                                width: "30%",
                                fontSize: "20px",
                                background: "black",
                                color: "white"
                            }}/>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button style={{
                        width: "10%",
                        height: "30px",
                        background: "black",
                        color: "white",
                        border: "2px solid coral"
                        }} onClick={onSubmitHandler}>코드 확인</button>
                </div>

            </div>
        </div>

    )
}
export default CodeCheck;