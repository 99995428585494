import {createSlice} from "@reduxjs/toolkit"

export const depositReducerSlice = createSlice({
    name: 'depositReducer',
    initialState: {
        page: 1,
        pageSize: 5,
        order: 0,
        isLoading: false,
        error: null,
        name : "",
        money : 0,
        no : "",
        bankPw : "",
        depositList : [],
        account : null,
        isConfirm : false,
        account_no : "",
        account_owner :"",
        account_bank : "",
        account_accountNum : "",
        depositMoney : 0,
        totalCount : 0,
        pw : "",

    },
    reducers: {
        getDepositReducer(state, action) {
            console.log("#reducers getDepositReducer start#");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.userNo = action.payload.userNo;
            state.order = action.payload.order;
        },
        setDepositReducer(state, action) {
            state.isLoading = false;
            console.log("#reducers setDepositReducer start#",action.payload.money,action.payload.name,action.payload.depositList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.money = action.payload.money;
            state.name = action.payload.name;
            state.depositList = action.payload.depositList;
            state.totalCount  = action.payload.totalCount;
        },
        failedGetDepositReducer(state, action) {
            console.log("#reducers failedGetDepositReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        getDepositCheckBankPwReducer(state, action) {
            console.log("#reducers getDepositCheckBankPw start#",action.payload.no,action.payload.name,action.payload.bankPw);
            state.isLoading = true;
            state.no = action.payload.no;
            state.name = action.payload.name;
            state.bankPw = action.payload.bankPw;
        },
        setDepositCheckBankPwReducer(state, action) {
            state.isLoading = false;
            console.log("#reducers setDepositCheckBankPwReducer start#",action.payload.no,action.payload.owner,action.payload.bank,action.payload.accountNum,action.payload.isConfirm);
            state.account_no = action.payload.no;
            state.account_owner = action.payload.owner;
            state.account_bank = action.payload.bank;
            state.account_accountNum = action.payload.accountNum;
            state.isConfirm = action.payload.isConfirm;
        },
        failedGetDepositCheckBankPwReducer(state, action) {
            console.log("#reducers failedGetDepositCheckBankPwReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setDepositMoney(state, action) {
            console.log("#reducers setDepositMoney start#");
            state.isLoading = false;
            state.depositMoney = action.payload;
        },
        setPw(state, action) {
            console.log("#reducers setPw start#");
            state.isLoading = false;
            state.pw = action.payload;
        },


    }
});

export const {
    getDepositReducer,
    setDepositReducer,
    failedGetDepositReducer,
    getDepositCheckBankPwReducer,
    setDepositCheckBankPwReducer,
    failedGetDepositCheckBankPwReducer,
    setDepositMoney,
    setPw
} = depositReducerSlice.actions;

export default depositReducerSlice.reducer;