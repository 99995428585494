import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import commonInfo from "../../config/commonInfo";

const Notice = ({notice, onRefresh}) => {

    const [noticeList, setNoticeList] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);

    const getNoticeList = () => {
        axios.get('/user/notice/get_notice_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            setNoticeList(res.data.noticeList)
        })
    }
    useEffect(getNoticeList, [])

    return (
        <div className="main">
            <div className="subtitle">
                    공지사항 Notice
            </div>

            <div className="mt-2">
                <table className="table table-bordered table-sm text-center align-middle noticet1">
                    <thead className="table-dark">
                        <tr>
                            <td width="100px">NO</td>
                            <td>공지내용</td>
                        </tr>
                    </thead>

                    {notice.map((notice, index) => (
                    <tbody key={notice.no}>
                        <tr>
                            <td className="noticeTitle">{index + 1}</td>
                            <td className="noticeTitle">
                                <Link className="nav-link" data-bs-toggle="collapse" to={"#collapse" + index}
                                      aria-expanded="false" aria-controls={"collapse" + index}>
                                    {notice.title}
                                </Link>
                            </td>
                        </tr>
                        <tr className="collapse" id={"collapse" + index}>
                            <td></td>
                            <td className="noticeContent">
                                {notice.cont}
                                <br></br>
                                <img src={commonInfo.imgUrl + notice.image} alt="" />
                            </td>
                        </tr>
                    </tbody>
                    ))}

                </table>
            </div>
        </div>
    )
}

export default Notice;