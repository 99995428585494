import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import commonInfo from "../../config/commonInfo";

const Faq = ({faq, onRefresh}) => {

    const [faqList, setFaqList] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);

    const getFaqList = () => {
        axios.get('/user/faq/get_faq_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            setFaqList(res.data.faqList)
        })
    }
    useEffect(getFaqList, [])

    return (
        <div className="main">
            <div className="subtitle">
                자주하는질문 Faq
            </div>

            <div className="Noticet1 mt-2">
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-dark">
                    <tr>
                        <td width="100px">NO</td>
                        <td>질문내용</td>
                    </tr>
                    </thead>
                    {faq.map((faq, index) => (
                        <tbody key={faq.no}>
                        <tr>
                            <td className="faqTitle">{index + 1}</td>
                            <td className="faqTitle">
                                <Link className="nav-link" data-bs-toggle="collapse" to={"#collapse" + index}
                                      aria-expanded="false" aria-controls={"collapse" + index}>
                                    {faq.title}
                                </Link>
                            </td>
                        </tr>
                        <tr className="collapse" id={"collapse" + index}>
                            <td></td>
                            <td className="faqContent">
                                {faq.cont}
                                <br></br>
                                <img src={commonInfo.imgUrl + faq.image} alt="" />
                            </td>
                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>

        </div>
    )
}

export default Faq;