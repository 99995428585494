import React, {useState} from 'react';
import axios from "axios";

const Join = () => {

    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [confirmPw, setConfirmPw] = useState("");
    const [nickName, setNickName] = useState("");
    const [telecom, setTelecom] = useState("1");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [bank, setBank] = useState("국민");
    const [withdraw_account, setWithdraw_account] = useState("");
    const [name, setName] = useState("");
    const [bank_pw, setBank_pw] = useState("");
    const [idCheck, setIdCheck] = useState(false);
    const [nickCheck, setNickCheck] = useState(false);
    const [accountCheck, setAccountCheck] = useState(false);

    const onIdHandler = (e) => {
        const currentId = e.currentTarget.value
        setId(currentId);
        const notKorean = /[ㄱ-ㅎ가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
        if (notKorean.test(currentId)) {
            alert("영어,숫자조합만 가능합니다")
            setId("")
        }
        setIdCheck(false);
    }
    const onPwHandler = (e) => {
        const currentPw = e.currentTarget.value
        setPw(currentPw);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentPw)) {
            alert("영어,숫자조합만 가능합니다")
            setPw("")
        }
    }
    const onConfirmPwHandler = (e) => {
        const currentConfirmPw = e.currentTarget.value
        setConfirmPw(currentConfirmPw);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentConfirmPw)) {
            alert("영어,숫자조합만 가능합니다")
            setConfirmPw("")
        }
    }
    const onNickNameHandler = (e) => {
        const currenNickName = e.currentTarget.value
        setNickName(currenNickName);
        const onlyKorean = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'/\\]/g;
        if (onlyKorean.test(currenNickName)) {
            alert("한글만 입력바랍니다")
            setNickName("")
        }
        setNickCheck(false);
    }

    const telecomList = [
        {value : 1, name : "SKT"},
        {value : 2, name : "KT"},
        {value : 3, name : "LG"},
        {value : 4, name : "알뜰SKT"},
        {value : 5, name : "알뜰KT"},
        {value : 6, name : "알뜰LG"},
    ]
    const onTelecomHandler = (e) => {
        setTelecom(e.currentTarget.value);
    }
    const onPhoneHandler = (e) => {
        const currentPhone = e.currentTarget.value
        setPhone(currentPhone);
        const onlyNumber = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[a-zA-Z]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
        if (onlyNumber.test(currentPhone)) {
            alert("숫자조합만 가능합니다")
            setPhone("")
        }
    }
    const onEmailHandler = (e) => {
        const currentEmail = e.currentTarget.value
        setEmail(currentEmail);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!#$%^&*_+=,;:\"'/\\]/g;
        if (notKorean.test(currentEmail)) {
            alert("영어,숫자조합만 가능합니다")
            setEmail("")
        }
    }
    const bankList = [
        {value : "국민", name : "국민"},
        {value : "기업", name : "기업"},
        {value : "농협", name : "농협"},
        {value : "신한", name : "신한"},
        {value : "우리", name : "우리"},
        {value : "우체국", name : "우체국"},
        {value : "새마을금고", name : "새마을금고"},
        {value : "수협", name : "수협"},
        {value : "카카오뱅크", name : "카카오뱅크"},
        {value : "케이뱅크", name : "케이뱅크"},
        {value : "KEB하나", name : "KEB하나"},
        {value : "SC제일", name : "SC제일"},
        {value : "한국시티", name : "한국시티"},
        {value : "경남", name : "경남"},
        {value : "광주", name : "광주"},
        {value : "대구", name : "대구"},
        {value : "부산", name : "부산"},
        {value : "전북", name : "전북"},
        {value : "제주", name : "제주"},
    ]
    const onBankHandler = (e) => {
        setBank(e.currentTarget.value);
    }
    const onWithdraw_accountHandler = (e) => {
        const currentWithdraw_account = e.currentTarget.value
        setWithdraw_account(currentWithdraw_account);
        const onlyNumber = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[a-zA-Z]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
        if (onlyNumber.test(currentWithdraw_account)) {
            alert("숫자조합만 가능합니다")
            setWithdraw_account("")
        }
        setAccountCheck(false);
    }
    const onNameHandler = (e) => {
        const currenName = e.currentTarget.value
        setName(currenName);
        const notNumber = /[0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'/\\]/g;
        if (notNumber.test(currenName)) {
            alert("한글,영어만 입력바랍니다")
            setName("")
        }
    }
    const onBank_pwHandler = (e) => {
        const currentBankPw = e.currentTarget.value
        setBank_pw(currentBankPw);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentBankPw)) {
            alert("영어,숫자조합만 가능합니다")
            setBank_pw("")
        }
    }
    const onIdCheckHandler = (e) => {
        axios.post('/user/member/check_id', {
            id: id
        }).then((res) => {
            if(res.data.isPossible) {
                alert("중복되는 아이디입니다.")
                setIdCheck(false);
            } else {
                alert("이용가능한 아이디입니다.")
                setIdCheck(true);
            }
        })
    }
    const onNickNameCheckHandler = (e) => {
        if (nickName.length < 2 || nickName.length > 7) {
            alert("닉네임은 2글자 이상 7글자 이하로 입렵바랍니다.")
            setNickCheck(false);
        } else {
            axios.post('/user/member/check_nickname', {
                nickname: nickName
            }).then((res) => {
                if(res.data.isPossible) {
                    alert("중복되는 닉네임입니다.")
                    setNickCheck(false);
                } else {
                    alert("이용가능한 닉네임입니다.")
                    setNickCheck(true);
                }
            })
        }
    }
    const onAccountCheckHandler = (e) => {
        axios.post('/user/member/check_account', {
            withdraw_account: withdraw_account
        }).then((res) => {
            if(res.data.isPossible) {
                alert("중복되는 계좌번호입니다.")
                setAccountCheck(false);
            } else {
                alert("이용가능한 계좌번호입니다.")
                setAccountCheck(true);
            }
        })
    }

    const memberJoin = (e) => {
        if(id === "") {
            alert("아이디를 입력해주시기 바랍니다")
        } else if(pw === "") {
            alert("비밀번호를 입력해주시기 바랍니다")
        } else if(nickName === "") {
            alert("닉네임을 입력해주시기 바랍니다")
        } else if(telecom === "") {
            alert("통신사를 선택해주시기 바랍니다")
        } else if(phone === "") {
            alert("전화번호를 입력해주시기 바랍니다")
        } else if(email === "") {
            alert("이메일을 입력해주시기 바랍니다")
        } else if(bank === "") {
            alert("은행을 선택해주시기 바랍니다")
        } else if(withdraw_account === "") {
            alert("출금계좌를 입력해주시기 바랍니다")
        } else if(name === "") {
            alert("예금주를 입력해주시기 바랍니다")
        } else if(bank_pw === "") {
            alert("환전비밀번호를 입력해주시기 바랍니다")
        } else {
            if(idCheck === false){
                alert("아이디 중복체크해주시기 바랍니다.")
            } else if(nickCheck === false){
                alert("닉네임 중복체크해주시기 바랍니다.")
            } else if(accountCheck === false){
                alert("계좌번호 중복체크해주시기 바랍니다.")
            } else if(pw !== confirmPw){
                alert("비밀번호와 바밀번호확인이 다릅니다")
            } else {
            axios.post('/user/member/join', {
                id: id,
                pw: pw,
                nickname: nickName,
                telecom: telecom,
                phone: phone,
                email: email,
                bank: bank,
                withdraw_account: withdraw_account,
                name: name,
                bank_pw: bank_pw
            }).then((res) => {
                if(res.data.isInserted){
                    alert("회원가입신청이 완료되었습니다. 가입전화 승인후 접속가능합니다");
                    window.location.href = "/"
                } else {
                    alert("회원가입 실패하였습니다. 잠시후 재가입해주시기 바랍니다.")
                }
                })
            }
        }
    }

    return (
        <div className="main">
            <div className="subtitle">
                회원가입
            </div>
            <div className="text-center">
                <div className="codeCheck">
                    <div className="triangle-right-off">
                        <div className="step">1 단계</div>
                    </div>
                    <div className="stepDetail my-auto">
                        <span style={{color: "gray"}}>가입코드 입력</span><br></br>가입코드를 입력해주세요
                    </div>

                    <div className="triangle-right">
                        <div className="step">2 단계</div>
                    </div>
                    <div className="stepDetail my-auto">
                        <span style={{color: "gold"}}>가입정보 입력</span><br></br>기입에 필요한 정보를 입력하세요
                    </div>

                    <div className="triangle-right-off">
                        <div className="step">3 단계</div>
                    </div>
                    <div className="stepDetail my-auto">
                        <span style={{color: "gray"}}>가입완료 및 이용안내</span><br></br>가입완료및 사이트 이용안내
                    </div>
                </div>
            </div>
            <div className="join p-2 d-flex" style={{
                flexDirection: "column"
            }}>
                <label>ID</label>
                <div className="d-flex">
                    <input type="text" style={{
                        width: "92%"
                    }} value={id} maxLength="12" onChange={onIdHandler} tabIndex={1} placeholder="ID는 영문+숫자 조합 12자이하"/>
                    <button type="button" className="btn btn-secondary btn-sm ms-1" tabIndex={2} style={{width: "8%"}} onClick={onIdCheckHandler}>중복체크</button>
                </div>

                <label>PW</label>
                <input type="password" maxLength="12" value={pw} tabIndex={3} placeholder="영문+숫자 조합 12자이하" onChange={onPwHandler} />

                <label>PW 확인</label>
                <input type="password" maxLength="12" value={confirmPw} tabIndex={4} placeholder="영문+숫자 조합 12자이하" onChange={onConfirmPwHandler} />

                <label>닉네임</label>
                <div className="my-auto d-flex">
                    <input type="text" style={{
                        width: "92%"
                    }} value={nickName} onChange={onNickNameHandler} tabIndex={5} placeholder="한글만 2~7자"/>
                    <button type="button" className="btn btn-secondary btn-sm ms-1" tabIndex={6} style={{width: "8%"}} onClick={onNickNameCheckHandler}>중복체크</button>
                </div>

                <label>연락처</label>
                <div className="d-flex">
                    <select className="form-select" onChange={onTelecomHandler} tabIndex={7} value={telecom} style={{
                        width: "120px",
                        height: "40px"
                    }}>
                        {telecomList.map((item, index) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </select>

                    <div style={{
                        width: "100%",
                    }}>
                        <input type="text" value={phone} onChange={onPhoneHandler} tabIndex={8} placeholder="전화번호" style={{
                            width: "100%",
                            height: "40px"
                        }}/>
                    </div>

                </div>


                <label>이메일</label>
                <input type="text" maxLength="30" value={email} tabIndex={9} onChange={onEmailHandler} />

                <label>은행</label>
                <select className="form-select" onChange={onBankHandler} tabIndex={10} value={bank} style={{
                    width: "100%",
                    height: "40px"
                }}>
                    {bankList.map((item, index) => (
                        <option key={item.value} value={item.value}>{item.name}</option>
                    ))}
                </select>

                <label>계좌번호</label>
                <div className="d-flex">
                    <input type="text" style={{
                        width: "92%"
                    }} value={withdraw_account} maxLength="20" onChange={onWithdraw_accountHandler} tabIndex={11} placeholder="- 없이 숫자만 입력"/>
                    <button type="button" className="btn btn-secondary btn-sm" style={{width: "8%"}} onClick={onAccountCheckHandler}>중복체크</button>
                </div>

                <label>예금주</label>
                <input type="text" maxLength="12" value={name} onChange={onNameHandler} tabIndex={12} placeholder="예금주 불일치시 환전불가"/>

                <label>환전비밀번호</label>
                <input type="text" value={bank_pw} maxLength="12" onChange={onBank_pwHandler} tabIndex={13} placeholder="영어,수자만"/>

                <br />

            </div>
            <div className="text-center">
                <button onClick={memberJoin} tabIndex={14}>회원가입</button>
            </div>

        </div>
    )
}

export default Join;