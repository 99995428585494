import {createSlice} from "@reduxjs/toolkit"

export const serviceCenterReducerSlice = createSlice({
    name: 'serviceCenterReducer',
    initialState: {
        page:1,
        pageSize:5,
        order:0,
        totalCount : 0,
        isLoading: false,
        error: null,
        helpList : [],
        title : "",
        cont : "",
        status : 1
    },
    reducers: {
        getServiceCenterReducer(state, action) {
            console.log("#reducers getServiceCenterReducer start#");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.userNo = action.payload.userNo;
            state.order = action.payload.order;
        },
        setServiceCenterReducer(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            console.log("#reducers setServiceCenterReducer start#",action.payload.helpList);
            state.helpList = action.payload.helpList;
            state.totalCount  = action.payload.totalCount;
        },
        failedGetServiceCenterReducer(state, action) {
            console.log("#reducers failedGetServiceCenterReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setTitle(state, action) {
            console.log("#reducers setTitle start#");
            state.title = action.payload;
        },
        setCont(state, action) {
            console.log("#reducers setTitle start#");
            state.cont = action.payload;
        },
    }
});

export const {
    getServiceCenterReducer,
    setServiceCenterReducer,
    failedGetServiceCenterReducer,
    setTitle,
    setCont,
} = serviceCenterReducerSlice.actions;

export default serviceCenterReducerSlice.reducer;